import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Segment,
  Grid,
  Loader,
  Header,
  Icon,
  Form,
  Divider,
  Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import TestataPagina from "../common/TestataPagina";
import GruppoSelezione from "../common/GruppoSelezione";
import SchedaAllievoPrivate from "./SchedaAllievoPrivate";
import RiquadroPreferenze from "./RiquadroPreferenze";
import { DayPickerSingleDateController } from "react-dates";
import moment from "moment";
import * as privateApi from "../../api/privateApi";
import * as calendarUtils from "../../utils/calendarUtils";
import * as cartActions from "../../redux/actions/cartActions";
import * as skiworkUtils from "../../utils/skiworkUtils";
import useWindowSize from "../../utils/useWindowSize";
import CartPortal from "../common/CartPortal";
import { useTranslation } from "react-i18next";
import InfoWeb from "../common/InfoWeb";
import ScalapayFrase from "../common/ScalapayFrase";
import RedirectWithTrack from "../common/RedirectWithTrack";

function PrivateProgramma({
  appData,
  datiScuola,
  loading,
  addPrivata,
  setReferrer,
  codScuola,
  cart,
  lang,
  ...props
}) {
  /////////////////////////////////////
  //STATE
  const [isLoading, setIsLoading] = useState(false);
  const [isDisponiblitaLoading, setIsDisponibilitaLoading] = useState(false);
  const [redirectToCart, setRedirectToCart] = useState(false);
  const [checkOutOpen, setCheckOutOpen] = useState(false);

  const [calendarioInfo, setCalendarioInfo] = useState(undefined);
  const [disponibilita, setDisponiblita] = useState(undefined);
  const [numAllievi, setNumAllievi] = useState(1);
  const [listaFasce, setListaFasce] = useState([]);
  const [listaFasceRaw, setListaFasceRaw] = useState([]);
  const [ore, setOre] = useState(0);
  const [orarioDesc, setOrarioDesc] = useState("");
  const [isOfferta, setIsOfferta] = useState(false);
  const [prezzo, setPrezzo] = useState(0);
  const [prezzoPag, setPrezzoPag] = useState(0);
  const [prezzoListino, setPrezzoListino] = useState(0);
  const [fascia, setFascia] = useState(undefined);
  // const [fasciaSelOfferta, setFasciaSelOfferta] = false;
  const [listaOre, setListaOre] = useState([]);
  const [data, setData] = useState(null);
  const [datiAllievi, setDatiAllievi] = useState([
    { nome: "", cognome: "", livello: "-1", anno: "", sesso: "M" },
    { nome: "", cognome: "", livello: "-1", anno: "", sesso: "M" },
    { nome: "", cognome: "", livello: "-1", anno: "", sesso: "M" },
    { nome: "", cognome: "", livello: "-1", anno: "", sesso: "M" },
    { nome: "", cognome: "", livello: "-1", anno: "", sesso: "M" },
  ]);
  const [errorsAllievi, setErrorsAllievi] = useState(() => {
    const errorsArray = [];
    for (let i = 0; i < 5; i++) {
      errorsArray.push({});
    }
    return errorsArray;
  });

  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  const [opzioniSesso] = useState([
    {
      key: "M",
      text: "M",
      img: require(`../../assets/images/people/Male2.jpg`),
    },
    {
      key: "F",
      text: "F",
      img: require(`../../assets/images/people/Female2.jpg`),
    },
  ]);

  const [livelli] = useState(
    skiworkUtils.getLivelli(datiScuola, undefined, true)
  );
  const windowSize = useWindowSize();

  const inizioAllieviRef = useRef();

  const history = useHistory();

  const location = useLocation();

  const scrollRef = useRef(null);

  ///////////////////////////////////////////
  //EFFECT HOOKS
  useEffect(() => {
    setIsLoading(true);

    if (appData.prefsPrivate === undefined) {
      history.push(`/${lang}/${codScuola}${getTrackQueryString()}`);
    }

    let soloOfferte = false;
    let soloNormali = false;
    const searchParams = new URLSearchParams(location.search.toLowerCase());
    if (searchParams.has("offers")) {
      soloOfferte = searchParams.get("offers");
      soloNormali = searchParams.get("offers") === "false";
    }

    const loadCalendarInfoAsync = async () => {
      const res = await privateApi.getCalendarioInfo(
        datiScuola.id,
        appData.prefsPrivate.idMaestro,
        appData.prefsPrivate.idLingua,
        appData.prefsPrivate.idSpecialita,
        appData.prefsPrivate.idSesso,
        soloOfferte,
        soloNormali
      );
      setCalendarioInfo(res);
      setIsLoading(false);

      window.scrollTo(0, 0);
    };

    loadCalendarInfoAsync();
  }, []);

  useEffect(() => {
    if (disponibilita === undefined) return;
    let optOk = disponibilita.filter(
      (x) => (!x.offerta && x.numPersone === 1) || x.offerta
    );

    setListaFasce(
      optOk.map((x) => {
        var o = {
          key: x.idPeriodo.toString(),
          img: undefined,
          text: `${moment(x.dalle).format("HH:mm")} ${moment(x.alle).format(
            "HH:mm"
          )}`,
          additionalText: undefined,
        };
        if (x.offerta) {
          o.additionalText = `${x.numPersone} persone`;
        }

        return o;
      })
    );

    setListaFasceRaw(optOk);
    setFascia(undefined);
    setOre("1");
  }, [disponibilita]);

  useEffect(() => {
    ricalcolaPrezzo();
  }, [fascia, ore, numAllievi]);

  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
    }
  }, [appData.lang]);

  //////////////////////////////////////////
  //HANDLERS
  async function handleDateChange(date) {
    setData(date);
    setIsDisponibilitaLoading(true);

    let soloOfferte = false;
    let soloNormali = false;
    const searchParams = new URLSearchParams(location.search.toLowerCase());
    if (searchParams.has("offers")) {
      soloOfferte = searchParams.get("offers");
      soloNormali = searchParams.get("offers") === "false";
    }

    const res = await privateApi.getDisponibilita(
      datiScuola.id,
      appData.agenzia === undefined ? 0 : appData.agenzia.idAgenzia,
      date.format("YYYY-MM-DD"),
      appData.prefsPrivate.idMaestro,
      appData.prefsPrivate.idLingua,
      appData.prefsPrivate.idSpecialita,
      appData.prefsPrivate.idSesso,
      soloOfferte,
      soloNormali
    );

    setDisponiblita(res);
    setIsDisponibilitaLoading(false);
    setFascia(null);
  }

  const getTrackQueryString = () =>
    appData.trackId == null ? "" : `?t=${appData.trackId}`;

  const handleRightMount = () => {
    if (windowSize.width > 600) return;
    if (scrollRef.current) {
      console.log("scrollRef is now set:", scrollRef.current);
      // Example action: Scroll to the referenced element
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log("scrollRef is still null");
    }
  };

  function handleNumAllieviChange(numAllievi) {
    if (isOfferta) return;
    setNumAllievi(parseInt(numAllievi));
  }

  function handleNumOreChange(ore) {
    setOre(ore);
  }

  function handleFasciaChange(fascia) {
    setFascia(fascia);
    //orarioTmp = fascia;

    let start = datiScuola.minOrePrivate;
    let step = 0.5;

    if (datiScuola.mezzorePrivate === false) {
      step = 1;
    }

    let lOre = [];
    for (
      let i = start;
      i <=
      disponibilita.find((x) => x.idPeriodo === parseInt(fascia)).disponibilita;
      i += step
    ) {
      lOre.push({ key: i.toString(), img: undefined, text: i.toString() });
    }

    const fasciaTmp = disponibilita.find(
      (x) => x.idPeriodo === parseInt(fascia)
    );
    setOrarioDesc(
      `${moment(fasciaTmp.dalle).format("hh:mm")} - ${moment(
        fasciaTmp.alle
      ).format("hh:mm")}`
    );

    setIsOfferta(fasciaTmp.offerta);
    if (fasciaTmp.offerta) {
      setNumAllievi(fasciaTmp.numPersone.toString());
    }

    setListaOre(lOre);
    handleNumOreChange(lOre[0].key);
  }

  function handleAllievoChange(idx, field, value) {
    const datiAllieviTmp = [...datiAllievi];
    datiAllieviTmp[idx - 1][field] = value;
    setDatiAllievi(datiAllieviTmp);

    const errorsTmp = [...errorsAllievi];
    errorsTmp[idx - 1][field] = false;
    setErrorsAllievi(errorsTmp);
  }

  function checkErrors() {
    const errorsTmp = [...errorsAllievi];
    let error = false;
    for (let i = 0; i < numAllievi; i++) {
      if (datiAllievi[i].nome.length < 2) {
        errorsTmp[i].nome = true;
        error = true;
      }
      if (datiAllievi[i].cognome.length < 2) {
        errorsTmp[i].cognome = true;
        error = true;
      }
      if (datiAllievi[i].sesso.length < 1) {
        errorsTmp[i].sesso = true;
        error = true;
      }
      if (datiAllievi[i].anno < 1900) {
        errorsTmp[i].anno = true;
        error = true;
      }
      if (
        parseInt(datiAllievi[i].livello) <= 0 ||
        datiAllievi[i].livello === ""
      ) {
        errorsTmp[i].livello = true;
        error = true;
      }
    }

    for (let i = numAllievi; i < 5; i++) {
      errorsTmp[i] = {};
    }

    if (error) {
      inizioAllieviRef.current.scrollIntoView(true, {
        behavior: "smooth",
        block: "start",
      });
      setErrorsAllievi(errorsTmp);
    }

    return error;
  }

  function handleAggiungi() {
    if (checkErrors()) return;

    if (
      cart.righeCollettive.find((x) => x.roomingList === true) !== undefined
    ) {
      alert(
        "Nel carrello è già presente un corso con rooming list, non è possibile aggiungere un altro corso"
      );
      return;
    }

    //correggo i livelli
    for (let i = 0; i < datiAllievi.length; i++) {
      if (datiAllievi[i].nome !== "") {
        datiAllievi[i].livello = datiScuola.tLivelli.filter(
          (x) => x.id === parseInt(datiAllievi[i].livello)
        )[0].idLivelloIso;
      }
    }

    addPrivata({
      ...appData.prefsPrivate,
      numAllievi,
      datiAllievi,
      ore,
      prezzo,
      prezzoPag,
      prezzoListino,
      fascia,
      data,
      dalle: listaFasceRaw.find((x) => x.idPeriodo === parseInt(fascia)).dalle,
      alle: listaFasceRaw.find((x) => x.idPeriodo === parseInt(fascia)).alle,
      descFascia: listaFasce.filter((x) => x.key === fascia)[0].text,
      pacchetto: listaFasceRaw.find((x) => x.idPeriodo === parseInt(fascia))
        .offerta,
    });

    setReferrer("private");
    if (windowSize.width > 600) {
      setCheckOutOpen(true);
    } else {
      history.push(
        `/${appData.lang}/${appData.slug}/cart${getTrackQueryString()}`
      );
    }
  }

  function handleCartPortalClose() {
    setCheckOutOpen(false);
    setData(undefined);
  }

  ////////////////////////////////////
  //HELPERS
  function ricalcolaPrezzo() {
    if (fascia === null || fascia === undefined) return;
    if (numAllievi === null || numAllievi === undefined) return;

    //Recupero le info della fascia scelta
    const disp = disponibilita.find(
      (x) =>
        x.idPeriodo === parseInt(fascia) &&
        x.numPersone === parseInt(numAllievi)
    );

    //Se offerta, forzo il numero di ore
    if (disp.offerta) {
      setOre(disp.durata);
    }

    //Se offerta per il calcolo uso sempre un ora
    const oreCalcolo = disp.offerta ? 1 : ore;

    const d = disponibilita.find(
      (x) =>
        x.idPeriodo === parseInt(fascia) &&
        x.numPersone === parseInt(numAllievi)
    );
    setPrezzo(d.prezzo * oreCalcolo);
    setPrezzoPag(d.prezzoPag * oreCalcolo);
    setPrezzoListino(d.prezzoListino * oreCalcolo);
  }

  return (
    <Container>
      <div className="head-offset"></div>
      {redirectToCart && (
        <RedirectWithTrack to={`/${appData.lang}/${appData.slug}/cart`} push />
      )}
      <TestataPagina
        titolo={t("lezioni_private")}
        icon="user"
        sottoTitolo={t("definisci_programma")}
      />
      {(isLoading || loading || calendarioInfo === undefined) && (
        <Loader active size="huge"></Loader>
      )}
      {!isLoading && !loading && calendarioInfo !== undefined && (
        <Segment
          className="padding_0_lr contenitore-grande"
          style={{ marginBottom: "30px" }}
        >
          {calendarioInfo.noDisp && (
            <Segment basic textAlign="center">
              <Header as="h2">{t("nessuna_disponibilita")}</Header>
              <Button
                icon
                primary
                size="large"
                onClick={() => {
                  history.push(
                    `/${appData.lang}/${
                      appData.slug
                    }/private${getTrackQueryString()}`
                  );
                }}
              >
                <Icon name="arrow left" size="large"></Icon>
                {t("indietro")}
              </Button>
            </Segment>
          )}

          {!calendarioInfo.noDisp && (
            <Grid stackable>
              <Grid.Column
                width={7}
                textAlign="center"
                className="padding_5_lr"
              >
                <Left
                  datiScuola={datiScuola}
                  appData={appData}
                  calendarioInfo={calendarioInfo}
                  data={data}
                  onDateChange={handleDateChange}
                  t={t}
                />
              </Grid.Column>
              <Grid.Column width={9} className="padding_5_lr">
                <Right
                  isLoading={isDisponiblitaLoading}
                  data={data}
                  numAllievi={numAllievi}
                  onNumAllieviChange={handleNumAllieviChange}
                  listaFasce={listaFasce}
                  listaFasceRaw={listaFasceRaw}
                  fascia={fascia}
                  onFasciaChange={handleFasciaChange}
                  isOfferta={isOfferta}
                  listaOre={listaOre}
                  onNumOreChange={handleNumOreChange}
                  ore={ore}
                  livelli={livelli}
                  opzioniSesso={opzioniSesso}
                  datiAllievi={datiAllievi}
                  onAllievoChange={handleAllievoChange}
                  errorsAllievi={errorsAllievi}
                  prezzo={prezzo}
                  prezzoPag={prezzoPag}
                  prezzoListino={prezzoListino}
                  onAggiungi={handleAggiungi}
                  datiScuola={datiScuola}
                  inizioAllieviRef={inizioAllieviRef}
                  scrollRef={scrollRef}
                  onMount={handleRightMount}
                  t={t}
                />
              </Grid.Column>
            </Grid>
          )}
        </Segment>
      )}
      <CartPortal open={checkOutOpen} onClose={handleCartPortalClose} />
    </Container>
  );
}

function Left({
  datiScuola,
  appData,
  data,
  calendarioInfo,
  onDateChange,
  t,
  scrollRef,

  ...props
}) {
  const [focus, setFocus] = useState(null);
  const windowSize = useWindowSize();
  //const { i18n, t } = useTranslation();

  function onFocusChange(ev, data) {
    setFocus(ev.focused);
  }
  if (calendarioInfo.noDisp || calendarioInfo === undefined) {
    return <></>;
  } else {
    return (
      <>
        <Segment inverted textAlign="center" className="skiwork-header">
          {t("data_lezione")}
        </Segment>

        <Segment basic className="padding_0_lr">
          <DayPickerSingleDateController
            date={data}
            onDateChange={onDateChange} // PropTypes.func.isRequired
            focused={focus} // PropTypes.bool
            onFocusChange={onFocusChange} // PropTypes.func.isRequired
            isOutsideRange={(day) =>
              !calendarUtils.isInclusivelyAfterDay(
                day,
                moment(calendarioInfo.startDate)
              ) || calendarUtils.isAfterDay(day, moment(calendarioInfo.endDate))
            }
            isDayBlocked={(day1) =>
              calendarioInfo.giorniNonDisponibili.some((day2) =>
                calendarUtils.isSameDay(day1, moment(day2))
              )
            }
            //initialVisibleMonth={() => moment(calendarioInfo.startDate)}
            noBorder
            orientation={windowSize.width > 600 ? "horizontal" : "vertical"}
            hideKeyboardShortcutsPanel
          />
        </Segment>
        <RiquadroPreferenze
          specialita={datiScuola.specialita.find(
            (x) =>
              x.idspecialita === parseInt(appData.prefsPrivate.idSpecialita)
          )}
          idLinguaInUso={datiScuola.idLinguaInUso}
          maestro={appData.prefsPrivate.maestro}
          sesso={appData.prefsPrivate.sesso}
          lingua={appData.prefsPrivate.lingua}
          idLingua={appData.prefsPrivate.idLingua}
          codScuola={datiScuola.codiceScuola}
          t={t}
        ></RiquadroPreferenze>
        <div
          style={{
            //marginLeft: windowSize.width > 600 ? "1em" : "2px",
            marginTop: "2em",
          }}
        >
          {windowSize.width > 600 && (
            <InfoWeb
              testi={datiScuola.infoWeb.filter((x) => x.sezione === 1)}
              sezione={1}
            />
          )}
        </div>
      </>
    );
  }
}

function Right({
  isLoading,
  data,
  numAllievi,
  onNumAllieviChange,
  listaFasce,
  listaFasceRaw,
  fascia,
  onFasciaChange,
  isOfferta,
  listaOre,
  onNumOreChange,
  ore,
  livelli,
  opzioniSesso,
  datiAllievi,
  errorsAllievi,
  onAllievoChange,
  prezzo,
  prezzoPag,
  prezzoListino,
  onAggiungi,
  datiScuola,
  inizioAllieviRef,
  scrollRef,
  t,
  onMount,

  ...props
}) {
  useEffect(() => {
    console.log("Right component has mounted");
    if (onMount) {
      onMount(); // Notify parent that Right has mounted
    }
  }, [onMount]);

  const listaNumAllievi = Array.from({ length: 5 }, (_, i) => {
    return {
      key: (i + 1).toString(),
      text: (i + 1).toString(),
      img: require(`../../assets/images/people/${i + 1}.jpg`),
    };
  });
  const windowSize = useWindowSize();
  //const { i18n, t } = useTranslation();

  function renderAllievi() {
    const allieviElements = [];

    for (let i = 0; i < numAllievi; i++) {
      allieviElements.push(
        <SchedaAllievoPrivate
          num={i + 1}
          livelli={livelli}
          onFieldChange={onAllievoChange}
          datiAllievo={datiAllievi[i]}
          opzioniSesso={opzioniSesso}
          errors={errorsAllievi[i]}
          key={i}
          t={t}
        />
      );
    }

    return allieviElements;
  }

  function renderPrezzo() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ width: "320px" }}>
          {prezzo < prezzoListino && (
            <div className="riga-prezzo">
              <span className="prezzo_parziale">{t("prezzo_listino")}</span>{" "}
              <span className="prezzo_parziale prezzo-barrato">
                {ore}h x {datiScuola.valuta} {(prezzoListino / ore).toFixed(2)}
              </span>
            </div>
          )}
          <div className="riga-prezzo">
            <span className="prezzo_parziale">{t("prezzo_online")}</span>{" "}
            <span className="prezzo_parziale">
              {ore}h x {datiScuola.valuta} {(prezzo / ore).toFixed(2)}
            </span>
          </div>
          <Divider />
          <div className="riga-prezzo">
            <span className="prezzo_totale">{t("Totale")}</span>{" "}
            <span className="prezzo_totale">
              {datiScuola.valuta} {prezzo.toFixed(2)}
            </span>
          </div>
          <Divider />
          {datiScuola.tipoBanca_2 > 0 && (
            <div style={{ paddingTop: "14px" }}>
              <ScalapayFrase prezzo={(prezzo / 3).toFixed(2)} end={true} />
            </div>
          )}
        </div>

        {/* <div
          className="skiwork-testi dicitura-sconto"
          style={{ textAlign: "right" }}
        >
          {t("Prezzo_scontato")}
        </div> */}
        <Divider />
      </div>
    );
  }

  if (isLoading) {
    return <Loader active size="huge"></Loader>;
  } else {
    if (data === undefined || data === null) {
      return (
        <Segment>
          <Header as="h3" className="skiwork-titolo">
            <Icon name="arrow left"></Icon>
            <Header.Content>{t("scegli_data_lezione")}</Header.Content>
          </Header>
        </Segment>
      );
    } else {
      return (
        <Form>
          <div ref={scrollRef}></div>
          <Segment inverted textAlign="center" className="skiwork-header">
            {t("orario_allievi")}
          </Segment>
          <label>{t("data")}</label>
          <p className="skiwork-testo">{data.format("DD/MM/YYYY")}</p>

          {listaFasce.filter((x) => x.additionalText === undefined).length >
            0 && (
            <>
              <GruppoSelezione
                titolo={t("fasce_orarie_disponibili")}
                elementi={listaFasce.filter(
                  (x) => x.additionalText === undefined
                )}
                onChange={onFasciaChange}
                selezione={fascia}
                width={97}
              ></GruppoSelezione>
              <Divider />
            </>
          )}
          {listaFasceRaw.filter((x) => x.offerta === true).length > 0 && (
            <>
              <GruppoPacchetti
                titolo={t("pacchetti_offerta")}
                elementi={listaFasceRaw.filter((x) => x.offerta === true)}
                onChange={onFasciaChange}
                selezione={fascia}
                width={97}
                valuta={datiScuola.valuta}
                t={t}
              />
              <Divider />
            </>
          )}

          {!isOfferta && fascia !== undefined && fascia !== null && (
            <GruppoSelezione
              titolo={t("quante_ore")}
              elementi={listaOre}
              onChange={onNumOreChange}
              selezione={ore}
              width={97}
            ></GruppoSelezione>
          )}

          {!isOfferta && (
            <>
              <GruppoSelezione
                titolo={t("numero_allievi")}
                elementi={listaNumAllievi}
                onChange={onNumAllieviChange}
                selezione={numAllievi.toString()}
                width={97}
              ></GruppoSelezione>
              <Divider />
            </>
          )}

          {fascia !== undefined && fascia !== null && (
            <>
              <Segment inverted textAlign="center" className="skiwork-header">
                {t("allievi")}
              </Segment>
              <div ref={inizioAllieviRef}></div>
              {renderAllievi()}
              <Divider />
              {renderPrezzo()}
              <div
                style={{
                  textAlign: windowSize.width > 600 ? "right" : "center",
                }}
              >
                <Button
                  primary
                  onClick={onAggiungi}
                  size="big"
                  style={{ maxWidth: "300px" }}
                >
                  <Icon name="cart plus"></Icon>
                  {t("aggiungi_lezione")}
                </Button>
              </div>
            </>
          )}
          {windowSize.width <= 600 && (
            <div style={{ marginTop: "14px" }}>
              <InfoWeb
                testi={datiScuola.infoWeb.filter((x) => x.sezione === 1)}
                sezione={1}
              />
            </div>
          )}
        </Form>
      );
    }
  }
}

function GruppoPacchetti({ t, ...props }) {
  const [lista] = useState(() => {
    return props.elementi.map((x) => {
      return Object.assign({}, x, {
        key: parseInt(x.idPeriodo),
      });
    });
  });

  //const { i18n, t } = useTranslation();

  function handleClick(ev) {
    props.onChange(ev.currentTarget.attributes.name.textContent);
  }

  return (
    <div style={{ marginTop: "10px", marginBottom: "5px" }}>
      <label>{props.titolo}</label>
      <Grid>
        {lista.map((el, idx) => (
          <Grid.Column
            style={{
              width: `${props.width}px`,
              paddingRight: "7px",
            }}
            key={el.key}
          >
            <Segment
              textAlign="center"
              onClick={handleClick}
              style={{
                padding: "5px",
                marginTop: "5px",
                backgroundColor: "white",
                paddingBottom: "0px",
              }}
              className={`select_element ${
                parseInt(el.key) === parseInt(props.selezione) ? "active" : ""
              } `}
              name={el.key}
            >
              {el.img === undefined && (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    color: "black",
                    marginBottom: "0px",
                  }}
                >
                  {`${moment(el.dalle).format("HH:mm")} ${moment(
                    el.alle
                  ).format("HH:mm")}`}
                </p>
              )}
              <p
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  color: "black",
                  marginBottom: "0px",
                  marginTop: "0px",
                  fontWeight: "700",
                }}
              >
                {`(${moment
                  .duration(moment(el.alle).diff(moment(el.dalle)))
                  .asHours()}h)`}
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  color: "black",
                  marginBottom: "0px",
                  marginTop: "0px",
                }}
              >
                {`${el.numPersone} ${t("allievi")}`}
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  color: "black",
                  marginBottom: "0px",
                  marginTop: "5px",
                  fontWeight: "700",
                }}
              >
                {`${props.valuta} ${el.prezzo.toFixed(2)}`}
              </p>
            </Segment>
          </Grid.Column>
        ))}
      </Grid>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    cart: state.cartReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  addPrivata: cartActions.addPrivata,
  setReferrer: cartActions.setReferrer,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateProgramma);
