import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Redirect, useRouteMatch } from "react-router-dom";
import * as scuolaActions from "../../redux/actions/scuolaActions";
import * as appActions from "../../redux/actions/appActions";
import * as corsiActions from "../../redux/actions/corsiActions";
import { Loader, Container } from "semantic-ui-react";
import TestataPagina from "../common/TestataPagina";
import * as corsiApi from "../../api/corsiApi";
import ListaCategorieTesto from "./ListaCategorieTesto";
import GrigliaCorsi from "./GrigliaCorsi";
import ListaCorsi from "./ListaCorsi";
import ListaCategorieGrafica from "./ListaCategorieGrafica";
import moment from "moment";
import { useTranslation } from "react-i18next";
import RedirectWithTrack from "../common/RedirectWithTrack";

function CollettiveMain({
  loading,
  codScuola,
  lang,
  loadSchoolData,
  loadSessioni,
  setSlug,
  setLang,
  setTrackId,
  setCorsoDettagli,
  appData,
  datiScuola,
  clearAppData,

  ...props
}) {
  //////////////////////////////////////////
  //STATE
  const [idTipologia, setIdTipologia] = useState(-1);
  const [stileVisualizzazione, setStileVisualizzazione] = useState("g");
  const [listaCorsi, setListaCorsi] = useState(undefined);
  const [listaCorsiFiltrata, setListaCorsiFiltrata] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectToCorso, setRedirectToCorso] = useState(false);
  const [idCorso, setIdCorso] = useState(undefined);
  const rootMatch = useRouteMatch({
    path: "/:lang/:slug/collettive",
    exact: true,
  });

  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  //////////////////////////////////////////
  //REFS
  const elencoCorsiRef = useRef();

  //////////////////////////////////////////
  //EFFECT HOOKS
  useEffect(() => {
    //console.log(codScuola, appData.slug, lang, appData.lang);
    if (codScuola !== appData.slug || lang !== appData.lang) {
      clearAppData();
      loadSchoolData(codScuola, lang);
      setSlug(codScuola);
      setLang(lang);
    }
  }, []);

  useEffect(() => {
    if (datiScuola.id === undefined) return;
    setIsLoading(true);
    const getLista = async () => {
      const res = await corsiApi.getListaCorsi(
        datiScuola.id,
        moment().format("YYYY-MM-DD"),
        -1,
        datiScuola.idLinguaInUso,
        appData.agenzia === undefined ? 0 : appData.agenzia.idAgenzia
      );

      setListaCorsi(res);
      setIsLoading(false);
    };

    getLista();
  }, [datiScuola]);

  useEffect(() => {
    if (idTipologia > 0 && elencoCorsiRef.current !== undefined) {
      //console.log("SCROLL, IDTIpologia");
      elencoCorsiRef.current.scrollIntoView(true, {
        behavior: "smooth",
        block: "start",
      });
    }
  }, [idTipologia]);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(
  //     props.location.search.toLowerCase()
  //   );
  //   if (searchParams.has("t")) {
  //     setTrackId(searchParams.get("t"));
  //   } else {
  //     if (rootMatch.isExact) {
  //       const isPageRefresh =
  //         window.performance &&
  //         window.performance.getEntriesByType("navigation")[0].type ===
  //           "reload";

  //       if (!props.location.state || isPageRefresh) {
  //         setTrackId(null);
  //       }
  //     }
  //   }
  // }, [props.location.search]);

  useEffect(() => {
    if (datiScuola === undefined) return;
    if (datiScuola.tipologieCorsi === undefined) return;
    if (listaCorsi === undefined) return;
    var urlParams = new URLSearchParams(props.location.search.toLowerCase());
    if (urlParams.has("tipo")) {
      const tipo = datiScuola.tipologieCorsi.find(
        (x) => x.codifica === parseInt(urlParams.get("tipo"))
      );
      if (tipo === undefined) {
        setListaCorsiFiltrata([...listaCorsi]);
        return;
      }
      const newId = tipo.idTipologiaCorsoWeb;
      setListaCorsiFiltrata(
        listaCorsi.filter((x) => x.idtipologiaCorsoWeb === newId)
      );

      setIdTipologia(newId);
    } else {
      setListaCorsiFiltrata([...listaCorsi]);
      console.log("num corsi: " + listaCorsi.length);
    }

    if (appData.corsoDettagli !== undefined) {
      handleTipologiaChange(
        appData.corsoDettagli.idtipologiaCorsoWeb,
        undefined
      );
    }
  }, [listaCorsi]);

  useEffect(() => {
    if (idTipologia > 0 && elencoCorsiRef.current !== undefined) {
      console.log("listaCorsiFiltrata cambiata: " + listaCorsiFiltrata.length);
      elencoCorsiRef.current.scrollIntoView(true, {
        behavior: "smooth",
        block: "start",
      });
    }
  }, [listaCorsiFiltrata]);

  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
    }
  }, [appData.lang]);

  //////////////////////////////////////////
  //HANDLERS
  function handleTipologiaChange(id, data) {
    let newId = 0;
    if (data === undefined) {
      newId = id;
    } else {
      if (data.value === "") {
        setIdTipologia(0);
        setListaCorsiFiltrata([...listaCorsi]);
        return;
      }
      newId = data.value;
    }

    setListaCorsiFiltrata(
      listaCorsi
        .filter((x) => x.idtipologiaCorsoWeb === newId)
        .sort((a, b) => {
          if (a.ordine > b.ordine) return 1;
          if (b.ordine > a.ordine) return -1;
          return 0;
        })
    );

    setIdTipologia(newId);
  }
  // function handleStileVisualizzazioneChange(stile) {
  //   setStileVisualizzazione(stile);
  // }
  function handleCorsoSelezionato(idCorso) {
    setIdCorso(idCorso);
    setCorsoDettagli(listaCorsi.find((x) => x.idCorso === idCorso));
    loadSessioni(
      datiScuola.id,
      listaCorsi.find((x) => x.idCorso === idCorso).id,
      moment().format("YYYY-MM-DD"),
      appData.agenzia !== undefined ? appData.agenzia.idAgenzia : -1,
      datiScuola.idLinguaInUso
    );
    setRedirectToCorso(true);
  }

  if (
    loading ||
    appData.slug === undefined ||
    isLoading ||
    Object.keys(datiScuola).length === 0
  ) {
    return <Loader active size="huge"></Loader>;
  } else {
    return (
      <Container>
        <div className="head-offset"></div>
        {redirectToCorso && (
          <RedirectWithTrack
            to={`/${appData.lang}/${appData.slug}/collettive/${idCorso}`}
            push
          />
        )}
        <TestataPagina
          titolo={t("corsi_collettivi")}
          icon="group"
          sottoTitolo={t("scegli_corso")}
        />

        {/* {datiScuola.graficaCategorieWeb && ( */}
        {datiScuola.tipologieCorsi.length > 0 && (
          <ListaCategorieGrafica
            codScuola={datiScuola.codiceScuola}
            codPrimario={datiScuola.codicePrimario}
            tipologie={datiScuola.tipologieCorsi}
            idTipologia={idTipologia}
            stileVisualizzazione={stileVisualizzazione}
            onTipologiaChange={handleTipologiaChange}
            t={t}
          />
        )}
        {/* )} */}
        {/* {!datiScuola.graficaCategorieWeb && (
          <ListaCategorieTesto
            tipologie={datiScuola.tipologieCorsi}
            idTipologia={idTipologia}
            stileVisualizzazione={stileVisualizzazione}
            onTipologiaChange={handleTipologiaChange}
            onStileVisualizzazioneChange={handleStileVisualizzazioneChange}
          />
        )} */}

        {/* {listaCorsiFiltrata.length === 0 && <Segment>NO CORSI</Segment>}
        {listaCorsiFiltrata.length > 0 && ( */}
        <div ref={elencoCorsiRef} id="ElencoCorsiAnchor"></div>
        {/* {stileVisualizzazione === "g" && ( */}
        <>
          {/* {((datiScuola.graficaCategorieWeb && idTipologia > 0) ||
              !datiScuola.graficaCategorieWeb) > 0 && ( */}
          {((datiScuola.tipologieCorsi.length > 0 && idTipologia > 0) ||
            (datiScuola.tipologieCorsi.length === 0 && idTipologia <= 0)) && (
            <GrigliaCorsi
              listaCorsi={listaCorsiFiltrata}
              onCorsoSelezionato={handleCorsoSelezionato}
              codScuola={datiScuola.codiceScuola}
              codPrimario={datiScuola.codicePrimario}
              t={t}
            />
          )}
          {/* )} */}
        </>
        {/* )} */}
        {/* {stileVisualizzazione === "l" && (
            <ListaCorsi
              listaCorsi={listaCorsiFiltrata}
              onCorsoSelezionato={handleCorsoSelezionato}
              codScuola={datiScuola.codiceScuola}
            />
          )} */}
        {/* </div> */}
        {/* )} */}
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  setLang: appActions.setLang,
  setSlug: appActions.setSlug,
  setTrackId: appActions.setTrackId,
  setCorsoDettagli: appActions.setCorsoDettagli,
  loadSchoolData: scuolaActions.loadSchoolData,
  loadSessioni: corsiActions.loadSessioni,
  clearAppData: appActions.clearAppData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollettiveMain);
