import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid, Icon, Menu, Input } from "semantic-ui-react";
import * as cartActions from "../redux/actions/cartActions";
import * as appActions from "../redux/actions/appActions";
import * as scuolaApi from "../api/scuolaApi";

function FooterMobile({
  datiScuola,
  appData,
  setReferrer,
  cartEmpty,
  setAgenzia,
  bookReset,
}) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  /////////////////////////////
  //STATE
  const [couponCode, setCouponCode] = useState("");
  const [viewCode, setViewCode] = useState(false);

  const getTrackQueryString = () =>
    appData.trackId == null ? "" : `?t=${appData.trackId}`;

  async function checkCoupon() {
    var res = await scuolaApi.getAgenzia(datiScuola.id, couponCode);
    if (res === null) {
      alert(t("CODICE_NON_VALIDO"));
    } else {
      //setAgenzia(res);
      alert(t("BENVENUTO") + " " + res.nomeAgenzia);

      cartEmpty();
      res = { ...res, fixed: false };
      setAgenzia(res);
      history.push(`/${appData.lang}/${appData.slug}${getTrackQueryString()}`);
    }
  }

  return (
    <>
      {/* <Grid>
        <Grid.Row className="skiwork_footer_mobile_rowlingua">
          <Grid.Column floated="right" width={4}>
            <Button
              fluid
              icon
              primary
              onClick={() => {
                history.goBack();
              }}
            >
              <Icon name="arrow left" size="large"></Icon>
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid> */}
      <div class="skiwork_footer_mobile">
        <Grid columns="equal">
          <Grid.Row
            style={{
              paddingBottom: "0px",
              display:
                viewCode || appData.agenzia !== undefined ? "inline" : "none",
            }}
          >
            <Grid.Column width="16" textAlign="center">
              <Menu compact style={{}} fluid>
                {datiScuola.agenzie && appData.agenzia === undefined && (
                  <Menu.Item style={{}}>
                    <Input
                      label="CODE"
                      action={{
                        icon: "search",
                        onClick: async () => {
                          await checkCoupon();
                        },
                      }}
                      value={couponCode}
                      onChange={(ev, data) => {
                        setCouponCode(data.value);
                      }}
                    ></Input>
                  </Menu.Item>
                )}
                {datiScuola.agenzie && appData.agenzia !== undefined && (
                  <Menu.Item>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setAgenzia(undefined);
                        setCouponCode("");
                        bookReset();
                        cartEmpty();
                        history.push(
                          `/${appData.lang}/${
                            appData.slug
                          }${getTrackQueryString()}`
                        );
                      }}
                    >
                      <Icon name="x" size="large" />
                    </span>
                    <span>
                      {t("LISTINO_PER")}
                      {appData.agenzia.nomeAgenzia}
                    </span>
                  </Menu.Item>
                )}
              </Menu>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: viewCode ? "0px" : "14px" }}>
            <Grid.Column>
              <Button
                fluid
                icon
                primary
                onClick={() => {
                  history.goBack();
                }}
              >
                <Icon name="arrow left" size="large"></Icon>
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                icon
                primary
                onClick={() => {
                  history.push(
                    `/${appData.lang}/${appData.slug}${getTrackQueryString()}`
                  );
                }}
              >
                <Icon name="home" size="large"></Icon>
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                icon
                primary
                color="yellow"
                onClick={() => {
                  setReferrer("");
                  history.push(
                    `/${appData.lang}/${
                      appData.slug
                    }/cart${getTrackQueryString()}`
                  );
                }}
              >
                <Icon name="cart" size="large"></Icon>
              </Button>
            </Grid.Column>
            {datiScuola.agenzie && (
              <Grid.Column>
                <Button
                  fluid
                  icon
                  primary
                  style={{ height: "100%" }}
                  onClick={() => {
                    setViewCode(!viewCode);
                  }}
                >
                  <span class="footer_code_button">CODE</span>
                </Button>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    datiScuola: state.scuolaReducer,
    appData: state.appReducer,
  };
}

const mapDispatchToProps = {
  setReferrer: cartActions.setReferrer,
  setAgenzia: appActions.setAgenzia,
  clearAppData: appActions.clearAppData,
  bookReset: appActions.bookReset,
  cartEmpty: cartActions.cartEmpty,
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterMobile);
