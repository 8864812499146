import React, { useEffect, useState, useRef } from "react";
import {
  Loader,
  Container,
  Segment,
  Button,
  Icon,
  Divider,
  List,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as scuolaActions from "../../redux/actions/scuolaActions";
import * as cartActions from "../../redux/actions/cartActions";
import * as corsiApi from "../../api/corsiApi";
import TestataPagina from "../common/TestataPagina";
import SchedaAllievoCorso from "./SchedaAllievoCorso";
import useWindowSize from "../../utils/useWindowSize";
import * as checkoutApi from "../../api/checkoutApi";
//import CartMain from "../cart/CartMain";
import CartPortal from "../common/CartPortal";
import { useTranslation } from "react-i18next";
import * as skiworkUtilis from "../../utils/skiworkUtils";
import ScalapayFrase from "../common/ScalapayFrase";
import SchedaAllievoCorsoRooming from "./SchedaAllievoCorsoRooming";
import Prezzo2 from "./Prezzo2";

function CollettiveAllievi({
  appData,
  datiScuola,
  addCollettiva,
  setReferrer,
  cart,
  ...props
}) {
  ///////////////////////////////////////
  //STATE
  const [roomingListMode, setRoomingListMode] = useState(
    () =>
      cart.righeCollettive.length === 0 &&
      appData.corsoDettagli.roomingList &&
      appData?.agenzia?.tipo === "agente"
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isValid] = useState(true);
  const [checkOutOpen, setCheckOutOpen] = useState(false);
  const [listaServizi, setListaServizi] = useState(undefined);
  const [numAllieviRoomingList, setNumAllieviRoomingList] = useState(10);
  const [numAllieviOkRoomingList, setNumAllieviOkRoomingList] = useState(0);
  const [datiAllievi, setDatiAllievi] = useState(() => {
    const allieviArray = [];
    const numAllieviIniziale = roomingListMode
      ? numAllieviRoomingList
      : appData.prefsCorso.numAllievi;
    for (let i = 0; i < numAllieviIniziale; i++) {
      allieviArray.push({
        nome: "",
        cognome: "",
        sesso: "M",
        anno: 0,
        livello: "-1",
        dataNascita: null,
        luogoNascita: "",
      });
    }
    return allieviArray;
  });
  const [errorsAllievi, setErrorsAllievi] = useState(() => {
    const errorsArray = [];
    const numAllieviIniziale = roomingListMode
      ? numAllieviRoomingList
      : appData.prefsCorso.numAllievi;
    for (let i = 0; i < numAllieviIniziale; i++) {
      errorsArray.push({});
    }
    return errorsArray;
  });
  const [serviziAllievo, setServiziAllievo] = useState(() => {
    const serviziArray = [];
    const numAllieviIniziale = roomingListMode
      ? numAllieviRoomingList
      : appData.prefsCorso.numAllievi;
    for (let i = 0; i < numAllieviIniziale; i++) {
      serviziArray.push([]);
    }
    return serviziArray;
  });
  const windowSize = useWindowSize();
  const history = useHistory();
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);

  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  //SE varia il numero di allievi relativo alla rooming list, aplio o restringo i vettori
  useEffect(() => {
    if (!roomingListMode) return;

    const allieviDiff = numAllieviRoomingList - datiAllievi.length;
    if (allieviDiff > 0) {
      setDatiAllievi((prevDatiAllievi) => [
        ...prevDatiAllievi,
        ...new Array(allieviDiff).fill({
          nome: "",
          cognome: "",
          sesso: "M",
          anno: 0,
          livello: "-1",
          dataNascita: null,
          luogoNascita: "",
        }),
      ]);
      setServiziAllievo((prevServiziAllievo) => [
        ...prevServiziAllievo,
        ...new Array(allieviDiff).fill([]),
      ]);
      setErrorsAllievi((prevErrorsAllievi) => [...prevErrorsAllievi, {}]);

      console.log("UPDATED allievi");
    }
    if (allieviDiff < 0) {
      setDatiAllievi(datiAllievi.slice(0, numAllieviRoomingList));
      setServiziAllievo(serviziAllievo.slice(0, numAllieviRoomingList));
      setErrorsAllievi(errorsAllievi.slice(0, numAllieviRoomingList));
    }
  }, [numAllieviRoomingList]);

  const inizioAllieviRef = useRef();

  ///////////////////////////////////////
  //EFFECT HOOKS
  useEffect(() => {
    //todo effettuare check per coerenza lingua e scuola
    setIsLoading(true);
    const loadServizi = async () => {
      const res = await corsiApi.getServiziDisponibili(
        datiScuola.id,
        appData.corsoDettagli.idCorso,
        appData.prefsCorso.primaData === undefined
          ? "1900-01-01"
          : appData.prefsCorso.primaData, //moment().format("YYYY-MM-DD"),
        datiScuola.idLinguaInUso,
        -1
      );
      setListaServizi(res.listaTipologie);
      setIsLoading(false);

      //Aggiungo eventuali servizi obbligatori agli allievi
      if (res.listaTipologie.length > 0) {
        const serviziAllievoTmp = [...serviziAllievo];
        for (let i = 0; i < res.listaTipologie.length; i++) {
          const serviziObbligatori = res.listaTipologie[i].servizi.filter(
            (x) => x.obbligatorio === true
          );
          if (serviziObbligatori.length > 0) {
            for (let i = 0; i < serviziAllievoTmp.length; i++) {
              serviziAllievoTmp[i] = serviziAllievoTmp[i].concat(
                serviziObbligatori.map((x) => {
                  return {
                    id: x.idServizio,
                    prezzo: x.prezzo,
                    descrizione: x.descrizione,
                    obbligatorio: true,
                    prezzoAlGiorno: x.prezzoAlGiorno,
                  };
                })
              );
            }
          }
        }
        setServiziAllievo(serviziAllievoTmp);
      }
      // if (res.listaTipologie.length > 0) {
      //   const serviziObbligatori = res.listaTipologie[0].servizi.filter(
      //     (x) => x.obbligatorio === true
      //   );
      //   if (serviziObbligatori.length > 0) {
      //     const serviziAllievoTmp = [...serviziAllievo];
      //     for (let i = 0; i < serviziAllievoTmp.length; i++) {
      //       serviziAllievoTmp[i] = serviziAllievoTmp[i].concat(
      //         serviziObbligatori.map((x) => {
      //           return {
      //             id: x.idServizio,
      //             prezzo: x.prezzo,
      //             descrizione: x.descrizione,
      //             obbligatorio: true,
      //             prezzoAlGiorno: x.prezzoAlGiorno,
      //           };
      //         })
      //       );
      //     }
      //     setServiziAllievo(serviziAllievoTmp);
      //   }
      // }
    };
    loadServizi();
  }, []);

  useEffect(() => {
    const allieviOk = datiAllievi.filter(
      (x) => x.cognome !== "" && x.nome !== ""
    );

    setNumAllieviOkRoomingList(allieviOk.length);
  }, [datiAllievi]);

  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
    }
  }, [appData.lang]);

  //////////////////////////////////////////////
  //HANDLERS
  function handleAddServizio(allievo, idServizio, prezzo, descrizione) {
    //Se il servizio esiste già per l'allievo, lo rimuovo
    const idx = serviziAllievo[allievo - 1].findIndex(
      (x) => x.id === idServizio
    );

    let tuttiServizi = [...serviziAllievo];
    //let sa = [...serviziAllievo[allievo - 1]];

    if (idx < 0) {
      //aggiunta
      tuttiServizi[allievo - 1] = tuttiServizi[allievo - 1].concat({
        id: idServizio,
        prezzo: prezzo,
        descrizione: descrizione,
      });
    } else {
      //rimozione
      tuttiServizi[allievo - 1] = tuttiServizi[allievo - 1].slice();
      tuttiServizi[allievo - 1].splice(idx, 1);
    }

    setServiziAllievo(tuttiServizi);
  }

  function handleAllievoChange(idx, field, value) {
    const datiAllieviTmp = [...datiAllievi];

    datiAllieviTmp[idx - 1][field] = value;
    setDatiAllievi(datiAllieviTmp);

    const errorsTmp = [...errorsAllievi];
    errorsTmp[idx - 1][field] = false;
    setErrorsAllievi(errorsTmp);
  }

  const getTrackQueryString = () =>
    appData.trackId == null ? "" : `?t=${appData.trackId}`;

  async function handleAggiuntaCarrello() {
    if (checkErrors()) {
      return;
    }

    //Verifico la data/anno di nascita
    const allievi = datiAllievi;
    if (datiScuola.dataLuogoNascita === true) {
      for (let i = 0; i < allievi.length; i++) {
        allievi[i].anno = allievi[i].dataNascita.getFullYear();
      }
    }

    //correggo i livelli

    for (let i = 0; i < allievi.length; i++) {
      if (parseInt(allievi[i].livello) !== -1) {
        if (allievi[i].cognome !== "") {
          allievi[i].livello = datiScuola.tLivelli.filter(
            (x) => x.id === parseInt(allievi[i].livello)
          )[0].idLivelloIso;
        }
      }
    }

    if (roomingListMode) {
      //Estraggo il vettore con gli allievi effettivi
      const allieviOk = datiAllievi.filter(
        (x) => x.cognome !== "" && x.nome !== ""
      );

      if (
        !window.confirm(
          `Confermi la prenotazione per ${allieviOk.length} persone?`
        )
      ) {
        return;
      }

      const righeTmp = [];
      righeTmp.push({
        ...appData.corsoDettagli,
        startDate: appData.prefsCorso.primaData,
        numGiorniScelti: appData.prefsCorso.numGiorni,
        numSessioni: appData.prefsCorso.numSessioni,
        numAllievi: allieviOk.length,
        prezzo: appData.prefsCorso.prezzo,
        prezzoPag: appData.prefsCorso.prezzoPag,
        listaDate: appData.prefsCorso.listaDate,
        allievi: allieviOk,
        serviziAllievo: new Array(100).fill([]),
        roomingList: true,
      });

      setIsLoading(true);

      var result = await checkoutApi.CheckoutCarrello({
        idScuola: datiScuola.id,
        codScuola: datiScuola.codiceScuola,
        note: "",
        idLingua: datiScuola.idLinguaInUso,
        ipAddress: "",
        isAgenteMaestro: true,
        nomeAgenzia: appData.agenzia.nomeAgenzia,
        idAgenzia: appData.agenzia.idAgenzia,
        idBuono: 0,
        UsaAnagraficaAgenzia: true,
        numBanca: 0,
        cliente: {
          idscuola: datiScuola.id,
          codiceScuola: datiScuola.codiceScuola,
          ...{
            nome: "",
            cognome: "",
            nazione: "",
            citta: "",
            cap: "",
            indirizzo: "",
            prefisso: "",
            telefono: "",
            email: "",
            cf: "",
            dataNascita: "",
            luogoNascita: "",
          },
        },
        corsiCollettivi: righeTmp.map((r) => {
          return {
            idCorso: r.id,
            idCorsoSkiwork: r.idCorso,
            startDate: r.startDate,
            listaDate: r.listaDate,
            numGiorni: r.numGiorniScelti,
            numSessioni: r.numSessioni,
            prezzo: r.prezzo,
            prezzoPag: r.prezzoPag,
            allievi: r.allievi.map((a) => {
              return {
                nome: a.nome,
                cognome: a.cognome,
                sesso: a.sesso,
                anno: a.anno,
                livello: a.livello,
                dataNascita: a.dataNascita,
                luogoNascita: a.luogoNascita,
                servizi: [],
              };
            }),
          };
        }),
        lezioniPrivate: [],
        fastBooking: [],
        buoni: [],
      });

      setIsLoading(false);
      if (result.result === null) {
        alert("problemi nella richiesta di pamento, riprovare più tardi");
        setIsLoading(false);
        return;
      }
    } else {
      addCollettiva({
        ...appData.corsoDettagli,
        startDate: appData.prefsCorso.primaData,
        numGiorniScelti: appData.prefsCorso.numGiorni,
        numSessioni: appData.prefsCorso.numSessioni,
        numAllievi: appData.prefsCorso.numAllievi,
        prezzo: appData.prefsCorso.prezzo,
        prezzoPag: appData.prefsCorso.prezzoPag,
        prezzoListino: appData.prefsCorso.prezzoListino,
        listaDate: appData.prefsCorso.listaDate,
        allievi: allievi,
        serviziAllievo: serviziAllievo,
        roomingList: false,
      });
    }

    if (roomingListMode) {
      alert("Prenotazione effettuata con successo");
      history.push(`/${appData.lang}/${appData.slug}`);
    } else {
      setReferrer("collettive");
      if (windowSize.width > 600) {
        setCheckOutOpen(true);
      } else {
        history.push(
          `/${appData.lang}/${appData.slug}/cart${getTrackQueryString()}`
        );
      }
    }
  }

  function handleCartPortalClose() {
    history.push(
      `/${appData.lang}/${appData.slug}/collettive${getTrackQueryString()}`
    );
  }

  /////////////////////////////////////////
  //HELPERS
  function getTotaleServizi() {
    let tot = 0;

    for (let [key, value] of Object.entries(
      //appData.corsoDettagli.serviziAllievo
      serviziAllievo
    )) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].prezzoAlGiorno) {
          tot += value[i].prezzo * appData.prefsCorso.numGiorni;
        } else {
          tot += value[i].prezzo;
        }
      }
    }

    return tot;
  }

  function checkErrors() {
    const errorsTmp = [...errorsAllievi];
    let error = false;
    if (roomingListMode) {
      const allieviOk = datiAllievi.filter(
        (x) => x.cognome !== "" && x.nome !== ""
      );

      if (allieviOk.length === 0) {
        error = true;
      }
      for (let i = 0; i < numAllieviRoomingList; i++) {
        // Both cognome and nome fields must be filled, if none is filled, skip the check
        if (
          datiAllievi[i].cognome.length < 1 &&
          datiAllievi[i].nome.length < 1
        ) {
          continue;
        }
        // Set error if only one of the two fields is filled
        if (
          datiAllievi[i].cognome.length < 1 &&
          datiAllievi[i].nome.length >= 1
        ) {
          errorsTmp[i].cognome = true;
          error = true;
        }
        if (
          datiAllievi[i].cognome.length >= 1 &&
          datiAllievi[i].nome.length < 1
        ) {
          errorsTmp[i].nome = true;
          error = true;
        }
      }
    } else {
      for (let i = 0; i < appData.prefsCorso.numAllievi; i++) {
        if (datiAllievi[i].nome.length < 2) {
          errorsTmp[i].nome = true;
          error = true;
        }
        if (datiAllievi[i].cognome.length < 2) {
          errorsTmp[i].cognome = true;
          error = true;
        }

        if (!roomingListMode) {
          if (datiAllievi[i].sesso.length < 1) {
            errorsTmp[i].sesso = true;
            error = true;
          }
          if (
            datiScuola.dataLuogoNascita !== true &&
            datiAllievi[i].anno < 1900
          ) {
            errorsTmp[i].anno = true;
            error = true;
          }

          const livelliAttivi = skiworkUtilis.getLivelli(
            datiScuola,
            appData.corsoDettagli.idCorso
          );
          if (
            parseInt(datiAllievi[i].livello) <= 0 &&
            livelliAttivi.length > 0
          ) {
            errorsTmp[i].livello = true;
            error = true;
          }
          if (
            datiScuola.dataLuogoNascita === true &&
            datiAllievi[i].luogoNascita.length < 1
          ) {
            errorsTmp[i].luogoNascita = true;
            error = true;
          }
          if (
            datiScuola.dataLuogoNascita === true &&
            datiAllievi[i].dataNascita === null
          ) {
            errorsTmp[i].dataNascita = true;
            error = true;
          }
        }
      }
    }
    if (error) {
      inizioAllieviRef.current.scrollIntoView(true, {
        behavior: "smooth",
        block: "start",
      });
      setErrorsAllievi(errorsTmp);
    }

    return error;
  }

  /////////////////////////////////////////////////
  //RENDER FUNTIONS
  function renderAllievi() {
    let allievi = [];
    for (let i = 0; i < appData.prefsCorso.numAllievi; i++) {
      allievi.push(
        <SchedaAllievoCorso
          num={i + 1}
          datiAllievo={datiAllievi[i]}
          datiCorso={appData.corsoDettagli}
          errors={errorsAllievi[i]}
          numGiorni={appData.prefsCorso.numGiorni}
          datiScuola={datiScuola}
          serviziDisponibili={listaServizi}
          onServizioAggiunto={handleAddServizio}
          onChange={handleAllievoChange}
          serviziAttivi={serviziAllievo[i]}
          livelli={datiScuola.livelli}
          key={i}
        />
      );
    }
    return allievi;
  }

  const renderAllieviRoomingList = () => {
    return (
      <Segment>
        <List divided relaxed>
          {datiAllievi.map((allievo, i) => (
            <SchedaAllievoCorsoRooming
              num={i + 1}
              datiAllievo={datiAllievi[i]}
              datiCorso={appData.corsoDettagli}
              errors={errorsAllievi[i]}
              numGiorni={appData.prefsCorso.numGiorni}
              datiScuola={datiScuola}
              serviziDisponibili={listaServizi}
              onServizioAggiunto={handleAddServizio}
              onChange={handleAllievoChange}
              serviziAttivi={serviziAllievo[i]}
              livelli={datiScuola.livelli}
              key={i}
            />
          ))}
        </List>
        <Segment basic textAlign="center">
          <Button
            primary
            onClick={() => {
              if (numAllieviRoomingList < 100) {
                setNumAllieviRoomingList(numAllieviRoomingList + 10);
              }
            }}
          >
            AGGIUNGI ALTRI ALLIEVI
          </Button>
        </Segment>
      </Segment>
    );
  };

  function renderPrezzo() {
    return (
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      >
        <Segment
          style={{
            // backgroundColor: "white",
            minWidth: "320px",
            maxWidth: "480px",
          }}
        >
          {appData.prefsCorso.prezzo < appData.prefsCorso.prezzoListino && (
            <>
              <div className="riga-prezzo">
                <span className="prezzo_parziale label-prezzo">
                  {t("prezzo_listino")}
                </span>
                <span>
                  <span className="prezzo_parziale label-prezzo">
                    {appData.prefsCorso.numAllievi} x
                  </span>
                  <span className="prezzo_parziale prezzo-barrato">
                    {datiScuola.valuta}{" "}
                    {appData.prefsCorso.prezzoListino.toFixed(2)}
                  </span>
                </span>
              </div>
            </>
          )}
          <div className="riga-prezzo">
            <span className="prezzo_parziale label-prezzo">
              {t("prezzo_online")}
            </span>
            <span>
              <span className="prezzo_parziale label-prezzo">
                {appData.prefsCorso.numAllievi} x
              </span>
              <span className="prezzo_parziale">
                {datiScuola.valuta} {appData.prefsCorso.prezzo.toFixed(2)}
              </span>
            </span>
          </div>
          {getTotaleServizi() > 0 && (
            <div className="riga-prezzo">
              <span className="prezzo_parziale label-prezzo">
                Totale servizi
              </span>

              <span className="prezzo_parziale">
                {datiScuola.valuta} {getTotaleServizi().toFixed(2)}
              </span>
            </div>
          )}
          <div className="riga-prezzo">
            <span className="prezzo_totale label-prezzo">{t("totale")}</span>

            <span className="prezzo_totale">
              {datiScuola.valuta}{" "}
              {(
                appData.prefsCorso.numAllievi * appData.prefsCorso.prezzo +
                getTotaleServizi()
              ).toFixed(2)}
            </span>
          </div>

          {appData.prefsCorso.prezzo !== appData.prefsCorso.prezzoPag && (
            <>
              <Divider />

              <div className="riga-prezzo">
                <span className="prezzo_parziale label-prezzo">
                  {t("acconto_da_pagare")}
                </span>

                <span className="prezzo_parziale">
                  {datiScuola.valuta}{" "}
                  {(
                    appData.prefsCorso.numAllievi * appData.prefsCorso.prezzoPag
                  ).toFixed(2)}
                </span>
              </div>
              <div className="riga-prezzo">
                <span className="prezzo_parziale label-prezzo">
                  {t("saldo")}
                </span>

                <span className="prezzo_parziale">
                  {datiScuola.valuta}{" "}
                  {(
                    appData.prefsCorso.numAllievi * appData.prefsCorso.prezzo +
                    getTotaleServizi() -
                    appData.prefsCorso.numAllievi * appData.prefsCorso.prezzoPag
                  ).toFixed(2)}
                </span>
              </div>
            </>
          )}
          {appData.prefsCorso.prezzo === appData.prefsCorso.prezzoPag && (
            <div className="riga-prezzo"></div>
          )}

          {datiScuola.tipoBanca_2 > 0 && (
            <div style={{ paddingTop: "28px" }}>
              <ScalapayFrase
                prezzo={(appData.prefsCorso.prezzoPag / 3).toFixed(2)}
                end={true}
              />
            </div>
          )}

          <Divider />
          <Button
            primary
            onClick={handleAggiuntaCarrello}
            disabled={!isValid}
            size={windowSize.width > 600 ? "big" : "large"}
          >
            <Icon name="cart arrow down" size="large" />
            {t("aggiungi_carrello")}
          </Button>
        </Segment>
      </div>
    );

    // const acconto = appData.prefsCorso.prezzo !== appData.prefsCorso.prezzoPag;
    // // if (appData.corsoDettagli.numAllievi === 1) {
    // //   if (acconto) {
    // //     return (
    // //       <Segment textAlign="right" basic className="padding_5_lr">
    // //         <label>{t("prezzo_corso")}</label>
    // //         <p className="prezzo_parziale" style={{ marginBottom: "0px" }}>
    // //           {datiScuola.valuta} {appData.prefsCorso.prezzo.toFixed(2)}
    // //         </p>
    // //         {getTotaleServizi() > 0 && (
    // //           <>
    // //             <label>{t("totale_servizi")}</label>
    // //             <p className="prezzo_parziale">
    // //               {datiScuola.valuta} {getTotaleServizi().toFixed(2)}
    // //             </p>
    // //           </>
    // //         )}
    // //         <Divider />
    // //         <label>{t("acconto")}</label>
    // //         <p className="prezzo_totale" style={{ marginBottom: "0px" }}>
    // //           {datiScuola.valuta} {appData.prefsCorso.prezzoPag.toFixed(2)}
    // //         </p>
    // //       </Segment>
    // //     );
    // //   }
    // //   return (
    // //     <Segment textAlign="right" basic className="padding_5_lr">
    // //       <label>{t("prezzo_corso")} X</label>
    // //       <p
    // //         className={
    // //           getTotaleServizi() > 0 ? "prezzo_parziale" : "prezzo_totale"
    // //         }
    // //       >
    // //         {datiScuola.valuta} {appData.prefsCorso.prezzo.toFixed(2)}
    // //       </p>
    // //       {getTotaleServizi() > 0 && (
    // //         <>
    // //           <label>{t("totale_servizi")}</label>
    // //           <p className="prezzo_parziale">
    // //             {datiScuola.valuta} {getTotaleServizi().toFixed(2)}
    // //           </p>
    // //           <Divider />
    // //           <label>{t("totale")}</label>
    // //           <p className="prezzo_totale" style={{ marginBottom: "0px" }}>
    // //             {datiScuola.valuta}{" "}
    // //             {(appData.prefsCorso.prezzo + getTotaleServizi()).toFixed(2)}
    // //           </p>
    // //         </>
    // //       )}
    // //       <div>xxx</div>
    // //     </Segment>
    // //   );
    // // } else
    // if (acconto) {
    //   return (
    //     <Segment textAlign="right" basic className="padding_5_lr">
    //       <label>
    //         {" "}
    //         {appData.prefsCorso.prezzo < appData.prefsCorso.prezzoListino
    //           ? t("prezzo_online")
    //           : t("prezzo")}
    //       </label>
    //       <p className="prezzo_parziale">
    //         {appData.prefsCorso.prezzo < appData.prefsCorso.prezzoListino && (
    //           <span
    //             style={{
    //               textDecoration: "line-through",
    //               marginRight: "7px",
    //             }}
    //           >
    //             {appData.prefsCorso.numAllievi} x {datiScuola.valuta}{" "}
    //             {appData.prefsCorso.prezzoListino.toFixed(2)}
    //           </span>
    //         )}
    //         <span>{appData.prefsCorso.numAllievi} x </span>
    //         {datiScuola.valuta} {appData.prefsCorso.prezzo.toFixed(2)}
    //       </p>
    //       {getTotaleServizi() > 0 && (
    //         <>
    //           <label>{t("totale_servizi")}</label>
    //           <p className="prezzo_parziale">
    //             <span>
    //               {datiScuola.valuta} {getTotaleServizi().toFixed(2)}
    //             </span>
    //           </p>
    //         </>
    //       )}
    //       <Divider></Divider>
    //       <p className="prezzo_parziale">
    //         {t("totale")} {datiScuola.valuta}{" "}
    //         {(
    //           parseFloat(appData.prefsCorso.prezzo) *
    //             parseInt(appData.prefsCorso.numAllievi) +
    //           getTotaleServizi()
    //         ).toFixed(2)}
    //       </p>
    //       <p className="prezzo_totale">
    //         {t("acconto")} {datiScuola.valuta}{" "}
    //         {(
    //           parseFloat(appData.prefsCorso.prezzoPag) *
    //           parseInt(appData.prefsCorso.numAllievi)
    //         ).toFixed(2)}
    //       </p>
    //     </Segment>
    //   );
    // }
    // return (
    //   <Segment textAlign="right" basic className="padding_5_lr">
    //     <label>{t("prezzo_corso")}</label>
    //     <p className="prezzo_parziale">
    //       {appData.prefsCorso.prezzo < appData.prefsCorso.prezzoListino && (
    //         <span
    //           style={{ textDecoration: "line-through", marginRight: "14px" }}
    //         >
    //           {appData.prefsCorso.numAllievi} x {datiScuola.valuta}{" "}
    //           {appData.prefsCorso.prezzoListino.toFixed(2)}
    //         </span>
    //       )}
    //       <span>{appData.prefsCorso.numAllievi} x </span>
    //       {datiScuola.valuta} {appData.prefsCorso.prezzo.toFixed(2)}
    //     </p>
    //     {getTotaleServizi() > 0 && (
    //       <>
    //         <label>{t("totale_servizi")}</label>
    //         <p className="prezzo_parziale">
    //           {datiScuola.valuta} {getTotaleServizi().toFixed(2)}
    //         </p>
    //       </>
    //     )}
    //     <Divider></Divider>
    //     <p className="prezzo_totale">
    //       {t("totale")}
    //       {appData.prefsCorso.prezzo < appData.prefsCorso.prezzoListino && (
    //         <span
    //           style={{
    //             textDecoration: "line-through",
    //             marginRight: "14px",
    //             marginLeft: "7px",
    //           }}
    //         >
    //           {datiScuola.valuta}{" "}
    //           {(
    //             parseFloat(appData.prefsCorso.prezzoListino) *
    //               parseInt(appData.prefsCorso.numAllievi) +
    //             getTotaleServizi()
    //           ).toFixed(2)}
    //         </span>
    //       )}
    //       {datiScuola.valuta}{" "}
    //       {(
    //         parseFloat(appData.prefsCorso.prezzo) *
    //           parseInt(appData.prefsCorso.numAllievi) +
    //         getTotaleServizi()
    //       ).toFixed(2)}
    //     </p>
    //     {datiScuola.tipoBanca_2 > 0 && (
    //       <ScalapayFrase
    //         prezzo={(
    //           (parseFloat(appData.prefsCorso.prezzo) *
    //             parseInt(appData.prefsCorso.numAllievi) +
    //             getTotaleServizi()) /
    //           3
    //         ).toFixed(2)}
    //         end={true}
    //       />
    //     )}
    //   </Segment>
    // );
  }

  if (isLoading) {
    return (
      <Container>
        <div className="head-offset"></div>
        <Loader active size="huge"></Loader>
      </Container>
    );
  } else {
    return (
      <Container>
        <div className="head-offset"></div>
        <TestataPagina
          titolo={t("corsi_collettivi")}
          icon="group"
          sottoTitolo={t("inserisci_dati_allievi")}
        />
        <div ref={inizioAllieviRef}></div>
        <Segment
          className="padding_5_lr contenitore-grande"
          style={{ marginBottom: "30px" }}
        >
          {cart?.righeCollettive?.length === 0 &&
            appData?.agenzia?.tipo === "agente" &&
            appData.corsoDettagli.roomingList && (
              <Segment basic textAlign="center">
                <Button
                  primary
                  onClick={() => {
                    setRoomingListMode(!roomingListMode);
                  }}
                >
                  {roomingListMode
                    ? "DISATTIVA ROOMING LIST MODE"
                    : "ATTIVA ROOMING LIST MODE"}
                </Button>
              </Segment>
            )}
          {!roomingListMode && renderAllievi()}
          {roomingListMode && renderAllieviRoomingList()}
          <Segment
            textAlign="right"
            basic
            className="padding_0_lr contenitore-grande"
          >
            {!roomingListMode && renderPrezzo()}
            {!roomingListMode && (
              <></>
              // <Segment
              //   basic
              //   textAlign={windowSize.width > 600 ? "right" : "center"}
              // >
              //   <Button
              //     primary
              //     onClick={handleAggiuntaCarrello}
              //     disabled={!isValid}
              //     size={windowSize.width > 600 ? "big" : "large"}
              //   >
              //     <Icon name="cart arrow down" size="large" />
              //     {t("aggiungi_carrello")}
              //   </Button>
              // </Segment>
            )}
            {roomingListMode && (
              <Segment
                basic
                textAlign={windowSize.width > 600 ? "right" : "center"}
              >
                <Button
                  primary
                  onClick={handleAggiuntaCarrello}
                  disabled={!(numAllieviOkRoomingList > 0)}
                  size={windowSize.width > 600 ? "big" : "large"}
                >
                  <Icon name="cart arrow down" size="large" />
                  PRENOTA ({numAllieviOkRoomingList} ISCRITTI)
                </Button>
              </Segment>
            )}
          </Segment>
        </Segment>

        <CartPortal open={checkOutOpen} onClose={handleCartPortalClose} />
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    cart: state.cartReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadSchoolData: scuolaActions.loadSchoolData,
  addCollettiva: cartActions.addCollettiva,
  setReferrer: cartActions.setReferrer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollettiveAllievi);
