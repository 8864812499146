// import * as StateConstants from "./stateConstants";

export default {
  //Indica il numero di api Call attualmente in esecuzione
  apiCallsInProgress: 0,

  //AppData - Stato corrente dell'applicazione
  appReducer: {
    trackId: null,
    // currentStep: 1, //1
    // maxStep: 1, //1
    // tipoLezione: "", //""
    // mode: StateConstants.MODE_NONE,
    // data: "2020-07-16T10:00:00.000Z",
    // specialita: -1, //-1
    // numAllievi: 1, //1
    // returnURL: "asdasd",
    // idCorso: 0,
    // corsiViewMode: "S",
  },

  //schoolData - Dati della scuola in uso
  scuolaReducer: {},

  corsi: { lista: [], sessioni: {}, servizi: [] },
  privateReducer: { disponibilita: [], disponibilitaFast: [] },
  cartReducer: {
    righePrivate: [],
    righeCollettive: [],
    righeFastBooking: [],
    righeBuoni: [],
    pagamento: {},
  },
};
