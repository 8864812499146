import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Loader,
  Container,
  Segment,
  Item,
  Divider,
  Grid,
} from "semantic-ui-react";
import TestataPagina from "../common/TestataPagina";
import defaultImgCorso from "../../assets/images/defaults/defaultCourse.jpg";
import DettaglioCorsoStd from "./DettaglioCorsoStd";
import * as appActions from "../../redux/actions/appActions";
import useWindowSize from "../../utils/useWindowSize";
import DettaglioCorsoPacchetto from "./DettaglioCorsoPacchetto";
import { useTranslation } from "react-i18next";
import RedirectWithTrack from "../common/RedirectWithTrack";

// import moment from "moment";
// import deLocale from "moment/locale/de";
// import enLocale from "moment/locale/en-gb";
// import itLocale from "moment/locale/it";
// import ruLocale from "moment/locale/ru";

function DettaglioCorso({
  appData,
  datiScuola,
  corsiData,
  cart,
  codScuola,

  setPrefsCorso,
  ...props
}) {
  /////////////////////////////////
  // STATE / constants
  const [isLoading, setIsLoading] = useState(false);
  const [redirectToAllievi, setRedirectToAllievi] = useState(false);
  const picUrl = `https://skiwork.blob.core.windows.net/webimg/${
    datiScuola.codicePrimario != null
      ? datiScuola.codicePrimario.toUpperCase()
      : codScuola.toUpperCase()
  }/${appData.corsoDettagli.picture}`;
  function addDefaultSrc(ev) {
    ev.target.src = defaultImgCorso;
  }
  const windowSize = useWindowSize();
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  /////////////////////////////////
  //EFFECT HOOKS
  useEffect(() => {}, []);

  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
      // if (i18n.language === "de") {
      //   moment.locale("de", deLocale);
      // }
      // if (i18n.language === "it") {
      //   moment.locale("it", itLocale);
      // }
      // if (i18n.language === "en") {
      //   moment.locale("en", enLocale);
      // }
      // if (i18n.language === "ru") {
      //   moment.locale("ru", ruLocale);
      // }
    }
  }, [appData.lang]);

  ////////////////////////////////
  //HANDLERS
  function handleAvanti(
    primaData,
    giorni,
    numSessioni,
    allievi,
    prezzo,
    prezzoPag,
    prezzoListino,
    listaDate
  ) {
    if (
      cart.righeCollettive.find((x) => x.roomingList === true) !== undefined
    ) {
      alert(
        "Nel carrello è già presente un corso con rooming list, non è possibile aggiungere un altro corso"
      );
      return;
    }

    setPrefsCorso({
      primaData: primaData,
      numGiorni: giorni,
      numSessioni: numSessioni,
      numAllievi: allievi,
      prezzo: prezzo,
      prezzoPag: prezzoPag,
      prezzoListino: prezzoListino,
      listaDate: listaDate,
    });

    setRedirectToAllievi(true);
  }

  function getImgWidth() {
    const w = windowSize.width;
    if (w >= 990) return "618px";
    if (w > 600 && w < 990) return "480px";
    return "100%";
  }

  if (isLoading) {
    return <Loader></Loader>;
  } else {
    return (
      <Container>
        <div className="head-offset"></div>
        {redirectToAllievi && (
          <RedirectWithTrack
            to={`/${appData.lang}/${appData.slug}/collettive/allievi`}
            push
          />
        )}
        <TestataPagina
          titolo={t("corsi_collettivi")}
          icon="group"
          sottoTitolo={t("scegli_corso")}
        />
        <Segment
          className="padding_0_lr contenitore-grande"
          style={{ marginBottom: "30px" }}
        >
          <Segment
            basic
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              paddingLeft: "17px",
            }}
            className="padding_0_top"
          >
            <Grid columns="equal" stackable>
              <Grid.Column
                width={windowSize.width > 600 ? 10 : 16}
                className="padding_0_lr"
              >
                <div
                  style={{
                    width: getImgWidth(),
                    height: "0",
                    paddingBottom: "66%",
                    position: "relative",
                  }}
                >
                  <img
                    onError={addDefaultSrc}
                    src={picUrl}
                    alt="category"
                    className="skiwork-image"
                    width="100%"
                    height="100%"
                  ></img>
                </div>
              </Grid.Column>
              <Grid.Column
                width={windowSize.width > 600 ? 6 : 16}
                className="padding_0_top padding_0_lr"
              >
                <Item.Group>
                  <Item>
                    <Item.Content>
                      <Item.Header
                        className="skiwork-titolo"
                        dangerouslySetInnerHTML={{
                          __html: appData.corsoDettagli.titolo,
                        }}
                      >
                        {/* {appData.corsoDettagli.titolo} */}
                      </Item.Header>
                      <Item.Meta
                        className="skiwork-sottotitolo"
                        dangerouslySetInnerHTML={{
                          __html: appData.corsoDettagli.sottoTitolo,
                        }}
                      >
                        {/* {appData.corsoDettagli.sottoTitolo} */}
                      </Item.Meta>
                      <Divider />
                      <Item.Description>
                        <p
                          className="skiwork-testi"
                          style={{ textAlign: "justify" }}
                          dangerouslySetInnerHTML={{
                            __html: appData.corsoDettagli.descrizione,
                          }}
                        ></p>
                      </Item.Description>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Grid.Column>
            </Grid>
          </Segment>
          {appData.corsoDettagli.tipoVenditaWeb === 1 && (
            <DettaglioCorsoPacchetto
              sessioniCorso={corsiData.sessioni}
              corso={appData.corsoDettagli}
              onAvantiClick={handleAvanti}
              datiScuola={datiScuola}
            />
          )}
          {appData.corsoDettagli.tipoVenditaWeb !== 1 && (
            <DettaglioCorsoStd
              sessioniCorso={corsiData.sessioni}
              corso={appData.corsoDettagli}
              onAvantiClick={handleAvanti}
              datiScuola={datiScuola}
              lang={appData.lang}
              roomingList={
                appData.corsoDettagli.roomingList &&
                appData.agenzia?.tipo === "agente"
              }
              // roomingList={
              //   appData.corsoDettagli.roomingList &&
              //   appData.agenzia.tipo === "agente"
              // }
            />
          )}
        </Segment>
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    cart: state.cartReducer,
    corsiData: state.corsi,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  setPrefsCorso: appActions.setPrefsCorso,
};

export default connect(mapStateToProps, mapDispatchToProps)(DettaglioCorso);
