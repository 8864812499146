import React, { useEffect, useState } from "react";
import {
  useLocation,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom";
//import "./App.css";
import "react-dates/lib/css/_datepicker.css";
import "./style/react_dates_override.css";
import "./assets/fomantic/dist/semantic.css";
import "./style/skiwork.css";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/it";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/de";
import parse from "html-react-parser";
import { Switch, Route, Redirect } from "react-router-dom";
import About from "./components/About";
import Entry from "./components/Entry";
import CollettiveMain from "./components/collettive/CollettiveMain";
import Footer from "./components/Footer";
import Testata from "./components/common/Testata";
import DettaglioCorso from "./components/collettive/DettaglioCorso";
import CollettiveAllievi from "./components/collettive/CollettiveAllievi";
import PrivateMain from "./components/private/PrivateMain";
import PrivateProgramma from "./components/private/PrivateProgramma";
import FastMain from "./components/fast/FastMain";
import PagamentiMain from "./components/pagamenti/PagamentiMain";
import TransactionOk from "./components/checkout/TransactionOk";
import TransactionKo from "./components/checkout/TransactionKo";
import FooterMobile from "./components/FooterMobile";
import useWindowSize from "./utils/useWindowSize";
import CartMain from "./components/cart/CartMain";
import CheckInMain from "./components/checkin/CheckInMain";
import BuoniMain from "./components/buoni/BuoniMain";
import CheckoutMain from "./components/checkout/CheckoutMain";
import CookieConsent from "react-cookie-consent";
import TestoPrivacy from "./components/common/TestoPrivacy";
import CodeError from "./components/pagamenti/CodeError";
import BuonoVoucher from "./components/buoni/BuonoVoucher";
import { useTranslation } from "react-i18next";
import CheckInOk from "./components/checkin/CheckInOk";
import * as cartActions from "./redux/actions/cartActions";
import * as appActions from "./redux/actions/appActions";
import * as scuolaApi from "./api/scuolaApi";
import RedirectWithTrack from "./components/common/RedirectWithTrack";

function App({
  dispatch,
  appData,
  datiScuola,
  cartEmpty,
  setAgenzia,
  setTrackId,
  bookReset,
}) {
  const location = useLocation();
  // const history = useHistory();
  const windowSize = useWindowSize();
  //const { lang } = useParams();
  const [showPrivacy, setShowPrivacy] = useState(false);
  //const [trackId, setTrackId] = useState(null);

  const rootMatch = useRouteMatch({ path: "/:lang/:slug", exact: true });

  function creaElementiSupporto() {
    const customCSS = document.createElement("link");
    customCSS.id = "customCSS";
    customCSS.rel = "stylesheet";
    customCSS.type = "text/css";
    if (appData.agenzia?.fixed && appData.agenzia?.style !== "") {
      customCSS.href =
        process.env.PUBLIC_URL +
        "/scuole/" +
        appData.slug +
        "/" +
        appData.agenzia.style +
        "/custom.css";
    } else {
      customCSS.href =
        process.env.PUBLIC_URL + "/scuole/" + appData.slug + "/custom.css";
    }
    document.getElementsByTagName("head")[0].appendChild(customCSS);

    //console.log(customCSS);

    const customScript = document.createElement("script");
    customScript.setAttribute("id", "customScript");

    //document.getElementsByTagName("head")[0].appendChild(customScript);
    document.getElementsByTagName("body")[0].appendChild(customScript);
  }

  useEffect(() => {
    if (appData !== undefined) {
      creaElementiSupporto();
    }
  }, []);

  useEffect(() => {
    //console.log("Agenzia cambiata");
    const el = document.getElementById("customCSS");
    if (el === null || el === undefined) return;
    if (appData.agenzia?.fixed && appData.agenzia?.style !== "") {
      el.href =
        process.env.PUBLIC_URL +
        "/scuole/" +
        appData.slug +
        "/" +
        appData.agenzia.style +
        "/custom.css";
    } else {
      el.href =
        process.env.PUBLIC_URL + "/scuole/" + appData.slug + "/custom.css";
    }
  }, [appData.agenzia]);

  useEffect(() => {
    if (appData !== undefined) {
      if (document.getElementById("customCSS") !== undefined) {
        const el = document.getElementById("customCSS");

        if (appData.agenzia?.fixed && appData.agenzia?.style !== "") {
          el.href =
            process.env.PUBLIC_URL +
            "/scuole/" +
            appData.slug +
            "/" +
            appData.agenzia.style +
            "/custom.css";
        } else {
          el.href =
            process.env.PUBLIC_URL + "/scuole/" + appData.slug + "/custom.css";
        }
      } else {
        creaElementiSupporto();
      }
      //Aggiungo lo stile per il background fisso
      const backgroundStyle = document.createElement("style");
      backgroundStyle.type = "text/css";
      backgroundStyle.appendChild(
        document.createTextNode(
          `@media screen and (min-width: 1025px) {body {background-image: url("scuole/${appData.slug}/sfondo.jpg");background-position: center center;background-size: cover;background-repeat: no-repeat;background-attachment: fixed;}}`
        )
      );

      document.getElementsByTagName("head")[0].appendChild(backgroundStyle);
    }
  }, [appData.slug]);

  useEffect(() => {
    //Rimuovo per sicurezza gli script vecchi
    const elementsToDelete = document.querySelectorAll("[data-skiwork]");
    const elementsToDeleteArray = Array.prototype.slice.call(elementsToDelete);
    elementsToDeleteArray.forEach(function (child) {
      child.parentNode.removeChild(child);
    });

    if (
      datiScuola.customCode !== undefined &&
      datiScuola.customCode !== "" &&
      datiScuola.customCode !== null
    ) {
      parse(datiScuola.customCode, {
        replace: (node) => {
          if (node.attribs === undefined) return;

          let externalScript = node.attribs.src ? true : false;

          const script = document.createElement("script");
          if (externalScript) {
            if (node.attribs.async === "") script.async = true;
            script.src = node.attribs.src;
          } else {
            script.innerHTML = node.children[0].data;
          }

          script.setAttribute("data-skiwork", true);
          document.body.append(script);
        },
      });
      //document.getElementById("customScript").innerHTML = datiScuola.customCode;
      //document.getElementById("customScript").onload();
    }
  }, [datiScuola.customCode]);

  useEffect(() => {
    console.log("LangChanged");
    switch (appData.lang) {
      case "fr":
      case "FR":
      case "Fr":
      case "fR":
        moment.locale("fr");
        break;
      case "it":
      case "IT":
      case "It":
      case "iT":
        moment.locale("it");
        break;
      case "de":
      case "DE":
      case "De":
      case "dE":
        moment.locale("de");
        break;
      default:
        moment.locale("en-gb");
    }
  }, [appData.lang]);

  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  useEffect(() => {
    const doWork = async () => {
      const searchParams = new URLSearchParams(location.search.toLowerCase());

      //Verifico se l'url rispetta esattamente questa path usando react router dom
      // e in quel caso controllo se c'è un coupon/hotel code
      if (rootMatch) {
        if (searchParams.has("hotelcode")) {
          await checkCoupon(searchParams.get("hotelcode"));
        } else {
          const isPageRefresh =
            window.performance &&
            window.performance.getEntriesByType("navigation")[0].type ===
              "reload";

          //console.log("isPageRefresh", isPageRefresh);

          if (!location.state || isPageRefresh) {
            // setAgenzia(undefined);
            // cartEmpty();
          }
        }

        // else {
        //   setAgenzia(undefined);
        //   cartEmpty();
        // }
      }

      //Controllo se ho attivato un codice tracciamento, se si lo salvo in sessione
      setTrackId(searchParams.get("t"));
    };

    doWork().catch(console.error);
  }, [location]);

  async function checkCoupon(couponCode) {
    var res = await scuolaApi.getAgenzia(datiScuola.id, couponCode);
    if (res === null) {
      alert(t("CODICE_NON_VALIDO"));
      setAgenzia(undefined);
      cartEmpty();
      bookReset();
    } else {
      //setAgenzia(res);
      //alert("BENVENUTO " + res.nomeAgenzia);

      cartEmpty();
      //res = { ...res, fixed: true };
      setAgenzia(res);
      //history.push(`/${appData.lang}/${appData.slug}`);
    }
  }

  // useEffect(() => {
  //   //if (lang === undefined) return;
  //   const l = lang;
  //   if (l !== appData.lang) {
  //     debugger;
  //   }
  // }, [{ lang }]);

  return (
    <>
      <div className="skiwork_main">
        <Testata></Testata>
        <Switch>
          <Route
            exact
            path="/:slug"
            component={(props) => (
              <RedirectWithTrack to={`/it/${props.match.params.slug}`} />
            )}
          />
          <Route exact path="/:lang/:slug" component={Entry} />

          <Route exact path="/:lang/:slug/fast" component={FastMain} />
          <Route exact path="/:lang/:slug/checkout" component={CheckoutMain} />

          <Route exact path="/:lang/:slug/private" component={PrivateMain} />
          <Route
            exact
            path="/:lang/:slug/pagamenti"
            component={PagamentiMain}
          />
          <Route
            exact
            path="/:lang/:slug/pagamenti/codeerror"
            component={CodeError}
          />
          <Route exact path="/:lang/:slug/cart" component={CartMain} />
          <Route
            exact
            path="/:lang/:slug/private/programma"
            component={PrivateProgramma}
          />

          <Route
            exact
            path="/:lang/:slug/checkout/ok"
            component={TransactionOk}
          />

          <Route
            exact
            path="/:lang/:slug/checkout/ko"
            component={TransactionKo}
          />

          <Route
            exact
            path="/:lang/:slug/collettive"
            component={CollettiveMain}
          />

          <Route exact path="/:lang/:slug/buoni" component={BuoniMain} />
          <Route
            exact
            path="/:lang/:slug/buoni/:codiceBuono"
            component={BuonoVoucher}
          />

          <Route exact path="/:lang/:slug/checkin" component={CheckInMain} />
          <Route exact path="/:lang/:slug/checkin/ok" component={CheckInOk} />
          <Route
            exact
            path="/:lang/:slug/collettive/allievi"
            component={CollettiveAllievi}
          />
          <Route
            exact
            path="/:lang/:slug/collettive/:idCorso"
            component={DettaglioCorso}
          />
          <Route component={About} />
        </Switch>
      </div>
      {windowSize.width < 600 ? <FooterMobile></FooterMobile> : <></>}
      <Footer></Footer>
      <TestoPrivacy
        isOpen={showPrivacy}
        onClose={() => {
          setShowPrivacy(false);
        }}
        t={t}
      />
      <CookieConsent buttonText={t("ho_capito")}>
        {t("sito_informativa")}{" "}
        <a
          href
          onClick={() => {
            setShowPrivacy(true);
          }}
        >
          {t("informativa")}
        </a>
      </CookieConsent>
      {/* <script>{`${datiScuola.customCode}`}</script> */}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    //params: ownProps.match,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
  };
}

const mapDispatchToProps = {
  setAgenzia: appActions.setAgenzia,
  setTrackId: appActions.setTrackId,
  cartEmpty: cartActions.cartEmpty,
  bookReset: appActions.bookReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
