export function hasThreeConsecutiveChars(str) {
  for (let i = 0; i < str.length - 2; i++) {
    if (str[i] === str[i + 1] && str[i] === str[i + 2]) {
      return true;
    }
  }
  return false;
}

export function isValidEuropeanZipCode(zipCode, countryCode) {
  if (zipCode.length <= 3) {
    return false;
  }

  if (/^0+$/.test(zipCode)) {
    return false;
  }

  const zipCodePatterns = {
    AT: /^\d{4}$/, // Austria
    BE: /^\d{4}$/, // Belgium
    CH: /^\d{4}$/, // Switzerland
    DE: /^\d{5}$/, // Germany
    DK: /^\d{4}$/, // Denmark
    ES: /^\d{5}$/, // Spain
    FI: /^\d{5}$/, // Finland
    FR: /^\d{5}$/, // France
    //GB: /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/, // United Kingdom
    IT: /^\d{5}$/, // Italy
    //NL: /^\d{4} ?[A-Z]{2}$/, // Netherlands
    NO: /^\d{4}$/, // Norway
    //PT: /^\d{4}-\d{3}$/, // Portugal
    //SE: /^\d{3} ?\d{2}$/, // Sweden
    // Aggiungi altri paesi se necessario
  };

  const pattern = zipCodePatterns[countryCode.toUpperCase()];
  if (!pattern) {
    return true;
  }

  return pattern.test(zipCode);
}
