import React, { useEffect, useState } from "react";
import { Container, Segment, Loader, Card } from "semantic-ui-react";
import { connect } from "react-redux";
import TestataPagina from "../common/TestataPagina";
import SchedaCategoriaCard from "../collettive/SchedaCategoriaCard";
import * as scuolaActions from "../../redux/actions/scuolaActions";
import * as appActions from "../../redux/actions/appActions";
import * as cartActions from "../../redux/actions/cartActions";
import * as buoniApi from "../../api/buoniApi";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import InfoWeb from "../common/InfoWeb";
import RedirectWithTrack from "../common/RedirectWithTrack";

const BuoniMain = ({
  loading,
  codScuola,
  lang,
  loadSchoolData,
  setSlug,
  setLang,
  appData,
  datiScuola,
  clearAppData,

  addBuono,

  ...props
}) => {
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  const [redirectToCliente, setRedirectToCliente] = useState(false);

  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  const [tipiBuono, setTipiBuono] = useState([]);
  const [fetching, setFetching] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const handleTipoSelected = (id, data) => {
    addBuono(tipiBuono.find((x) => x.idtipoBuono === id));
  };

  useEffect(() => {
    if (codScuola !== appData.slug || lang !== appData.lang) {
      clearAppData();
      loadSchoolData(codScuola, lang);
      setSlug(codScuola);
      setLang(lang);
    }
  }, []);

  useEffect(() => {
    const caricaTipi = async () => {
      setFetching(true);
      const res = await buoniApi.GetTipiBuono(
        datiScuola.id,
        lang,
        datiScuola.codiceScuola
      );
      setTipiBuono(res);
      setFetching(false);
    };

    caricaTipi().catch(console.error);
  }, [datiScuola]);

  if (
    loading ||
    appData.slug === undefined ||
    datiScuola === undefined ||
    datiScuola === null ||
    Object.keys(datiScuola).length === 0
  ) {
    return <Loader active size="huge"></Loader>;
  } else
    return (
      <Container>
        <div className="head-offset"></div>
        {redirectToCliente && (
          <RedirectWithTrack
            to={`/${appData.lang}/${appData.slug}/buoni/cliente`}
            push
          />
        )}
        <TestataPagina
          titolo={t("BUONI-VOUCHER")}
          icon="gift"
          sottoTitolo={t("Acquista_qui_buono")}
        />
        {(loading || fetching) && <Loader active size="huge"></Loader>}

        {!loading && !fetching && (
          <Segment className="padding_0_lr contenitore-grande">
            <Card.Group
              centered
              className="padding_5_lr"
              // style={{ display: isOpen ? "flex" : "none" }}
            >
              {tipiBuono.map((tipo) => {
                const tipoCorso = {
                  pictureWeb1: "TEXT",
                  titolo: tipo.titoloWeb,
                  sottoTitolo: tipo.descrizioneWeb,
                  valore: tipo.valore,
                  valuta: datiScuola.valuta,
                  idTipologiaCorsoWeb: tipo.idtipoBuono,
                  regalo: tipo.regalo,
                };
                return (
                  <SchedaCategoriaCard
                    tipologia={tipoCorso}
                    t={t}
                    onSelect={handleTipoSelected}
                    codScuola={datiScuola.codiceScuola}
                    popup
                  />
                );
              })}
            </Card.Group>
            <div
              style={{
                //marginLeft: windowSize.width > 600 ? "2em" : "2px",
                marginTop: "2em",
              }}
            >
              <InfoWeb
                testi={datiScuola.infoWeb.filter((x) => x.sezione === 3)}
                sezione={3}
              />
            </div>
          </Segment>
        )}
      </Container>
    );
};

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  setLang: appActions.setLang,
  setSlug: appActions.setSlug,
  loadSchoolData: scuolaActions.loadSchoolData,
  addBuono: cartActions.addBuono,
  clearAppData: appActions.clearAppData,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuoniMain);
