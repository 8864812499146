import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Segment,
  Header,
  Icon,
  Form,
  Button,
  Divider,
} from "semantic-ui-react";
import { DayPickerRangeController } from "react-dates";
import moment from "moment";
import * as calendarUtils from "../../utils/calendarUtils";
import useWindowSize from "../../utils/useWindowSize";
import Prezzo2 from "./Prezzo2";
import { useTranslation } from "react-i18next";
import Mappa from "../common/Mappa";
import styles from "./DettaglioCorsoStd.module.css";
import InfoWeb from "../common/InfoWeb";
import { max } from "moment";

function DettaglioCorsoStd({
  sessioniCorso,
  corso,
  datiScuola,
  // roomingList,
  ...props
}) {
  ////////////////////////////////
  //STATE
  const [stateStartDate, setStateStartDate] = useState(null);
  const [stateEndDate, setStateEndDate] = useState(null);
  const [stateFocusedInput, setStateFocusedInput] = useState("startDate");

  const [blockedDays, setBlockedDays] = useState([]); //Elenco giorni in cui NON c'è corso
  const [startDays, setStartDays] = useState([]); //Elenco giorni nei quali si può partire con il corso
  const [corsoConContinuita, setCorsoConContinuita] = useState(true);
  const [rigaPartenza, setRigaPartenza] = useState(0);

  const [sessioneSelezionata, setSessioneSelezionata] = useState({});

  const [numGiorniDisponibili, setNumGiorniDisponibili] = useState([]);
  const [numGiorniScelti, setNumGiorniScelti] = useState(-1);
  //const [sessioniEffettive, setSessioniEffettive] = useState(-1);
  const [numAllievi, setNumAllievi] = useState(1);

  const [programma, setProgramma] = useState([]);
  const [coloriCalendario, setColoriCalendario] = useState({
    start: "",
    corso: "",
  });

  const [coordinateRitrovo, setCoordinateRitrovo] = useState(undefined);
  const [mapVisible, setMapVisible] = useState(false);
  const windowSize = useWindowSize();
  const dettagliCorsoRef = useRef(null);

  const numAllieviDisponibili = (() => {
    const arrayAllievi = [];

    let maxAllievi = 3;
    // if (roomingList) {
    //   maxAllievi = 100;
    // }

    for (let i = 1; i <= maxAllievi; i++) {
      arrayAllievi.push({ key: i, text: i, value: i });
    }

    return arrayAllievi;
  })();

  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  var tmpData;
  var tmpSessione;

  ////////////////////////////////////
  //EFFECT HOOKS
  useEffect(() => {
    // switch (props.lang) {
    //   case "fr":
    //   case "FR":
    //   case "Fr":
    //   case "fR":
    //     moment.locale("fr");
    //     break;
    //   case "it":
    //   case "IT":
    //   case "It":
    //   case "iT":
    //     moment.locale("it");
    //     break;
    //   case "de":
    //   case "DE":
    //   case "De":
    //   case "dE":
    //     moment.locale("de");
    //     break;
    //   default:
    //     moment.locale("en-gb");
    // }

    let blockedDaysTmp = [];
    let startDaysTmp = [];

    const min = moment(sessioniCorso.minDate);
    const max = moment(sessioniCorso.maxDate);

    //for (let m = min; m.isBefore(max); m.add(1, "days")) {
    for (let m = min; m.isSameOrBefore(max); m.add(1, "days")) {
      //per ogni giorno verifico se è disabilitato o se è un "primo giorno"
      let found = false;

      for (let i = 0; i < sessioniCorso.sessioni.length; i++) {
        if (
          m >= moment(sessioniCorso.sessioni[i].dal) &&
          m <= moment(sessioniCorso.sessioni[i].al)
        ) {
          found = true;

          //Ho trovato la sessione, quindi verifico se è un "primo giorno"
          if (corso.soloDalPrimoGiorno) {
            if (m.isSame(moment(sessioniCorso.sessioni[i].dal))) {
              startDaysTmp.push(moment(m.format("YYYY-MM-DD")));
            }
          } else {
            for (
              let s = 0;
              s <
              sessioniCorso.sessioni[i].listaDateEffettive.length -
                corso.giorniMinCorso +
                1;
              s++
            ) {
              startDaysTmp.push(
                moment(sessioniCorso.sessioni[i].listaDateEffettive[s])
              );
            }
          }
        }
      }

      //per i corsi senza continuità blocco eventuali giorni non disponibili
      let giornoOk = false;
      const sessionTmp = getSessione(m);
      if (sessionTmp !== null) {
        for (let gd = 0; gd < sessionTmp.righe.length; gd++) {
          if (calendarUtils.isSameDay(moment(sessionTmp.righe[gd].data), m)) {
            giornoOk = true;
            break;
          }
        }
        if (!giornoOk) {
          found = false;
        }
      } else {
        found = false;
      }

      if (!found) {
        blockedDaysTmp.push(moment(m.format("YYYY-MM-DD")));
      }
    }

    setBlockedDays(blockedDaysTmp);
    setStartDays(startDaysTmp);

    const colori = { start: "", corso: "" };
    let tmpElement = document.createElement("div");
    tmpElement.setAttribute("class", "CalendarDay__highlighted_calendar");
    document.body.appendChild(tmpElement);
    colori.start = getComputedStyle(tmpElement).backgroundColor;
    tmpElement.removeAttribute("class");
    tmpElement.setAttribute("class", "CalendarDay__default");
    colori.corso = getComputedStyle(tmpElement).backgroundColor;
    document.body.removeChild(tmpElement);

    setColoriCalendario(colori);
  }, []);

  useEffect(() => {
    if (sessioneSelezionata === undefined) return;

    if (coordinateRitrovo === undefined) {
      if (!sessioneSelezionata.hasOwnProperty("coordinateRitrovo")) return;

      if (sessioneSelezionata.coordinateRitrovo.length < 20) {
        setCoordinateRitrovo(undefined);
        return;
      }

      //provo ad estrapolare i valori
      const values = sessioneSelezionata.coordinateRitrovo.split(",");
      if (values.length !== 3) {
        setCoordinateRitrovo(undefined);
        return;
      }

      const coord = { lat: 0.0, lng: 0.0, z: 10 };
      coord.lat = parseFloat(values[0]);
      coord.lng = parseFloat(values[1]);
      coord.z = parseFloat(values[2]);

      setCoordinateRitrovo(coord);
    }
  }, [sessioneSelezionata]);

  useEffect(() => {
    //console.log("dettagliCorsoRef.current: " + dettagliCorsoRef.current);
    if (dettagliCorsoRef.current !== null) {
      dettagliCorsoRef.current.scrollIntoView({ smooth: true });
    }
  }, [dettagliCorsoRef.current]);

  /////////////////////////////////////
  //HANDLERS
  function onStartDateChange(date) {
    //console.log("***start-startdate");
    if (date === null) return;

    if (startDays.some((day) => day.isSame(date, "day"))) {
      tmpData = date.startOf("day");
      setStateStartDate(date);

      if (!sessioneSelezionata.hasOwnProperty("coordinateRitrovo")) return;

      if (sessioneSelezionata.coordinateRitrovo.length < 20) {
        setCoordinateRitrovo(undefined);
        return;
      }

      //provo ad estrapolare i valori
      const values = sessioneSelezionata.coordinateRitrovo.split(",");
      if (values.length !== 3) {
        setCoordinateRitrovo(undefined);
        return;
      }

      const coord = { lat: 0.0, lng: 0.0, z: 10 };
      coord.lat = parseFloat(values[0]);
      coord.lng = parseFloat(values[1]);
      coord.z = parseFloat(values[2]);

      setCoordinateRitrovo(coord);

      if (dettagliCorsoRef.current !== null) {
        dettagliCorsoRef.current.scrollIntoView({ smooth: true });
      }
    } else {
      setNumGiorniDisponibili([]);
      setNumGiorniScelti(-1);
      tmpData = moment("2100-01-01");
      // dettagliCorsoRef.current = null;
    }
    //console.log("***end-startdate");
  }

  function onEndDateChange(date) {
    console.log("***start-enddate");
    if (date === null) return;

    if (tmpData === undefined) {
      tmpData = stateStartDate;
    }

    if (date !== null) {
      console.log(`New End Date: ${date.format("DD-MM-YYYY")}`);
    }

    if (calendarUtils.isInclusivelyAfterDay(date, tmpData)) {
      setStateEndDate(moment(date.format("YYYY-MM-DD")));
      if (tmpSessione === undefined) {
        tmpSessione = getSessione(tmpData);
      }

      //Trovo l'indice della data di partenza e di fine nell'array delle date
      let startDayIndex = 0;
      let endDayIndex = 0;
      if (corsoConContinuita) {
        for (let i = 0; i < tmpSessione.listaDateEffettive.length; i++) {
          if (
            tmpData.format("YYYY-MM-DD") ===
            moment(tmpSessione.listaDateEffettive[i]).format("YYYY-MM-DD")
          ) {
            startDayIndex = i;
          }
          if (
            date.format("YYYY-MM-DD") ===
            moment(tmpSessione.listaDateEffettive[i]).format("YYYY-MM-DD")
          ) {
            endDayIndex = i;
          }
        }
      } else {
        for (let i = 0; i < tmpSessione.listaDateEffettive.length; i++) {
          if (
            tmpData.format("YYYY-MM-DD") ===
            moment(tmpSessione.listaDateEffettive[i]).format("YYYY-MM-DD")
          ) {
            startDayIndex = i;
          }
        }
        if (numGiorniScelti > 0) {
          endDayIndex = startDayIndex + numGiorniScelti - 1;
        } else {
          endDayIndex = startDayIndex + corso.giorniCorso - 1;
        }
        if (endDayIndex > tmpSessione.listaDateEffettive.length - 1) {
          endDayIndex = tmpSessione.listaDateEffettive.length - 1;
        }
      }

      setRigaPartenza(startDayIndex);

      const giorniArray = [];
      for (
        let i = corso.giorniMinCorso;
        i <=
        (tmpSessione.listaDateEffettive.length - startDayIndex >
        corso.giorniCorso
          ? corso.giorniCorso
          : tmpSessione.listaDateEffettive.length - startDayIndex);
        i++
      ) {
        giorniArray.push({ key: i, text: i, value: i });
      }

      setNumGiorniDisponibili(giorniArray);

      let arrayIdx = endDayIndex - startDayIndex - corso.giorniMinCorso + 1;
      if (arrayIdx > giorniArray.length - 1) {
        arrayIdx = giorniArray.length - 1;
      }

      // console.log("NUM GIORNI SCELTI: " + giorniArray[arrayIdx].value);
      if (giorniArray[arrayIdx] !== undefined) {
        setNumGiorniScelti(giorniArray[arrayIdx].value);
      }

      let minIndex = 1000;
      let maxIndex = -1;
      for (let i = 0; i < tmpSessione.righe.length; i++) {
        for (let d = startDayIndex; d <= endDayIndex; d++) {
          if (
            moment(tmpSessione.righe[i].data).format("YYYY-MM-DD") ===
            moment(tmpSessione.listaDateEffettive[d]).format("YYYY-MM-DD")
          ) {
            if (i < minIndex) {
              minIndex = i;
            }
            if (i > maxIndex) {
              maxIndex = i;
            }
          }
        }
      }

      const newProgramma = tmpSessione.righe.filter(
        (x, idx) => idx >= minIndex && idx <= maxIndex
      );
      setProgramma(newProgramma.map((x) => x.rigaStampa));
    }

    console.log("***end-enddate");
  }

  const getEndDayOffset = (day) => {
    let valore = 0;
    day = moment(day.format("YYYY-MM-DD"));
    if (startDays.some((s) => calendarUtils.isSameDay(s, day))) {
      let sessione = getSessione(day);
      setSessioneSelezionata(sessione);
      tmpSessione = sessione;

      //Verifico se nella sessione c'è continuità a partire dalla data selezionata
      let continuita = true;
      const NumGiorniAllaFine = moment(sessione.al).diff(day, "days");
      for (let i = 0; i < NumGiorniAllaFine; i++) {
        const testDay = moment(day.format("YYYY-MM-DD")).add(i, "d");
        let found = false;
        for (let r = 0; r < sessione.righe.length; r++) {
          if (
            moment(sessione.righe[r].data).format("YYYY-MM-DD") ===
            testDay.format("YYYY-MM-DD")
          ) {
            found = true;
          }
        }
        if (!found) continuita = false;
      }

      if (continuita) {
        //se posso faccio max giorni, altrimenti il massimo possibile
        let maxGiorni = corso.giorniCorso - 1;
        let newEndDate = new moment(day.format("YYYY-MM-DD")).add(
          maxGiorni,
          "days"
        );

        let fineSessione = moment(sessione.al);

        if (calendarUtils.isAfterDay(newEndDate, fineSessione)) {
          //Non riesco a fare maxGiorni, cerco il massimo che posso fare
          maxGiorni = fineSessione.diff(day, "days");
        }
        valore = maxGiorni;

        setCorsoConContinuita(true);
      } else {
        setCorsoConContinuita(false);
        return day.add(0, "days");
      }
    } else {
      setStateStartDate(null);
      setStateEndDate(null);
    }

    return day.add(valore, "days");
  };

  function handleNumGiorniChange(numGiorni) {
    setNumGiorniScelti(numGiorni);
    //ricalcolo la end date
    if (corso.tipoVenditaWeb !== 1) {
      if (corsoConContinuita) {
        onEndDateChange(stateStartDate.clone().add(numGiorni - 1, "days"));
      } else {
        const newProgramma = sessioneSelezionata.righe.filter(
          (x, idx) => idx >= rigaPartenza && idx < rigaPartenza + numGiorni
        );

        setProgramma(newProgramma.map((x) => x.rigaStampa));
      }
    }
  }

  ////////////////////
  //HELPERS
  function getNumSessioniEffettive() {
    let sessioniEffettiveTmp = 0;
    if (corsoConContinuita) {
      for (let i = 0; i < numGiorniScelti; i++) {
        //conto e sommo le sessioni della giornata
        for (let r = 0; r < sessioneSelezionata.righe.length; ++r) {
          if (
            moment(sessioneSelezionata.righe[r].data).format("DD/MM/YYYY") ===
            stateStartDate.clone().add(i, "days").format("DD/MM/YYYY")
          ) {
            sessioniEffettiveTmp++;
          }
        }
      }
    } else {
      for (let i = rigaPartenza; i < rigaPartenza + numGiorniScelti; i++) {
        for (let r = 0; r < sessioneSelezionata.righe.length; ++r) {
          if (
            moment(sessioneSelezionata.righe[r].data).format("DD/MM/YYYY") ===
            moment(sessioneSelezionata.listaDateEffettive[i]).format(
              "DD/MM/YYYY"
            )
          ) {
            sessioniEffettiveTmp++;
          }
        }
      }
    }
    return sessioniEffettiveTmp;
  }

  function getDataFineEffettiva() {
    if (corsoConContinuita) {
      return stateEndDate.format("DD-MM-YYYY");
    } else {
      return moment(
        sessioneSelezionata.listaDateEffettive[
          rigaPartenza + numGiorniScelti - 1
        ]
      ).format("DD-MM-YYYY");
    }
  }

  function getSessione(data) {
    // verifico prima se la data selezionata è una prima data
    for (let i = 0; i < sessioniCorso.sessioni.length; i++) {
      if (
        calendarUtils.isSameDay(data, moment(sessioniCorso.sessioni[i].dal))
      ) {
        return sessioniCorso.sessioni[i];
      }
    }

    //se non va a buon fine, allora cerco tra le date
    for (let i = 0; i < sessioniCorso.sessioni.length; i++) {
      if (
        data >= moment(sessioniCorso.sessioni[i].dal) &&
        data <= moment(sessioniCorso.sessioni[i].al)
      ) {
        return sessioniCorso.sessioni[i];
      }
    }
    return null;
  }

  function listino() {
    const listino =
      corso.tipoVenditaWeb === 1
        ? sessioniCorso.sessioni[0].listinoPacchetto
        : sessioneSelezionata.listino;

    return listino.find(
      (x) => parseInt(getNumSessioniEffettive()) === x.numGiorni
    );
  }

  return (
    <Segment
      basic
      className="padding_0_lr"
      style={{ marginTop: "0px", paddingTop: "0px", paddingLeft: "0px" }}
    >
      <Grid columns="equal" textAlign="left">
        <Grid.Column
          style={{
            paddingLeft: windowSize.width > 600 ? "0px" : "14px",
            margin: "0px",
          }}
          width={windowSize.width > 600 ? 10 : 16}
        >
          {windowSize.width < 600 && (
            <ScegliData
              coloriCalendario={coloriCalendario}
              windowSize={windowSize}
              t={t}
            />
          )}
          <DayPickerRangeController
            startDate={stateStartDate}
            endDate={stateEndDate}
            onDatesChange={({ startDate, endDate }) => {
              onStartDateChange(moment(startDate.format("YYYY-MM-DD")));
              onEndDateChange(moment(endDate.format("YYYY-MM-DD")));
            }}
            focusedInput={stateFocusedInput}
            onFocusChange={(focusedInput) => setStateFocusedInput(focusedInput)}
            endDateOffset={(day) => getEndDayOffset(day)}
            isOutsideRange={(day) =>
              !calendarUtils.isInclusivelyAfterDay(
                day,
                moment(sessioniCorso.minDate)
              ) || calendarUtils.isAfterDay(day, moment(sessioniCorso.maxDate))
            }
            isDayBlocked={(day1) =>
              blockedDays.some((day2) => calendarUtils.isSameDay(day1, day2))
            }
            isDayHighlighted={(day1) =>
              startDays.some((day2) => calendarUtils.isSameDay(day1, day2))
            }
            initialVisibleMonth={() => moment(sessioniCorso.minDate)}
            numberOfMonths={windowSize.width > 600 ? 2 : 1}
            //renderCalendarInfo={renderCalendarInfo}
            hideKeyboardShortcutsPanel={true}
            noBorder
            orientation={windowSize.width > 600 ? "horizontal" : "vertical"}
          />
          {windowSize.width > 600 && (
            <div
              style={{
                marginLeft: windowSize.width > 600 ? "2em" : "2px",
                marginTop: "2em",
              }}
            >
              <InfoWeb
                testi={datiScuola.infoWeb.filter((x) => x.sezione === 0)}
                sezione={0}
              />
            </div>
          )}
        </Grid.Column>
        <Grid.Column width={windowSize.width > 600 ? 6 : 16}>
          {(() => {
            if (numGiorniScelti <= 0) {
              if (windowSize.width > 600) {
                return (
                  <ScegliData
                    coloriCalendario={coloriCalendario}
                    windowSize={windowSize}
                    t={t}
                  />
                );
              } else return <></>;
            } else {
              return (
                <Segment basic className="padding_0_lr">
                  <div ref={dettagliCorsoRef}></div>
                  <Segment
                    inverted
                    className="skiwork-header"
                    textAlign="center"
                  >
                    {t("dettagli_corso")}
                  </Segment>
                  {windowSize.width > 600 && (
                    <Form>
                      <Form.Group widths="equal">
                        <Form.Dropdown
                          selection
                          label={t("numero_giorni")}
                          options={numGiorniDisponibili}
                          value={numGiorniScelti}
                          onChange={(e, data) => {
                            handleNumGiorniChange(data.value);
                          }}
                        ></Form.Dropdown>
                        <Form.Dropdown
                          selection
                          label={t("numero_allievi")}
                          options={numAllieviDisponibili}
                          value={numAllievi}
                          onChange={(e, data) => {
                            console.log(data.value);
                            setNumAllievi(data.value);
                          }}
                        ></Form.Dropdown>
                      </Form.Group>
                      <Divider></Divider>
                      <div className={styles.InfoBoxRowContainer}>
                        <label>{t("dal")}</label>
                        <label>{t("al")}</label>
                      </div>
                      <div className={styles.InfoBoxRowContainer}>
                        <span className={`${styles.Testo}`}>
                          {stateStartDate.format("DD-MM-YYYY")}
                        </span>
                        <span className={`${styles.Testo}`}>
                          {getDataFineEffettiva()}
                        </span>
                      </div>
                      {/* <Form.Group widths="equal">
                        <Form.Input
                          label={t("dal")}
                          value={stateStartDate.format("DD-MM-YYYY")}
                          readOnly
                          fluid
                          onChange={() => {}}
                        ></Form.Input>
                        <Form.Input
                          label={t("al")}
                          value={getDataFineEffettiva()}
                          readOnly
                          fluid
                          onChange={() => {}}
                        ></Form.Input>
                      </Form.Group> */}
                      <div className={styles.InfoBoxRowContainer}>
                        <label>{t("ritrovo")}</label>
                      </div>
                      <div className={styles.InfoBoxRowContainer}>
                        <span className={`${styles.Testo}`}>
                          {numGiorniScelti > 0
                            ? sessioneSelezionata.ritrovo
                            : ""}
                        </span>
                        {coordinateRitrovo && (
                          <span className={`${styles.IconaMappa}`}>
                            <Icon
                              name="map pin"
                              circular
                              link
                              color="red"
                              onClick={() => {
                                setMapVisible(!mapVisible);
                              }}
                            ></Icon>
                          </span>
                        )}
                      </div>
                      {mapVisible && (
                        <Mappa
                          ritrovo={t("ritrovo")}
                          lat={coordinateRitrovo.lat}
                          lng={coordinateRitrovo.lng}
                          zoom={coordinateRitrovo.z}
                        ></Mappa>
                      )}
                    </Form>
                  )}
                  {windowSize.width <= 600 && (
                    <Form>
                      <Form.Dropdown
                        selection
                        label={t("numero_giorni")}
                        options={numGiorniDisponibili}
                        value={numGiorniScelti}
                        onChange={(e, data) => {
                          handleNumGiorniChange(data.value);
                        }}
                      ></Form.Dropdown>
                      <Form.Dropdown
                        selection
                        label={t("numero_allievi")}
                        options={numAllieviDisponibili}
                        value={numAllievi}
                        onChange={(e, data) => {
                          setNumAllievi(data.value);
                        }}
                      ></Form.Dropdown>
                      <label>
                        {t("dal")} -&#62; {t("al")}
                      </label>
                      <span>
                        {stateStartDate.format("DD-MM-YYYY")} -&#62;{" "}
                        {getDataFineEffettiva()}
                      </span>
                      {/* <label>{t("al")}</label>
                      <span>{getDataFineEffettiva()}</span> */}
                      <label>{t("ritrovo")}</label>
                      <div className={styles.InfoBoxRowContainer}>
                        <span className={`${styles.Testo}`}>
                          {numGiorniScelti > 0
                            ? sessioneSelezionata.ritrovo
                            : ""}
                        </span>
                        {coordinateRitrovo && (
                          <span className={`${styles.IconaMappa}`}>
                            <Icon
                              name="map pin"
                              circular
                              link
                              color="red"
                              onClick={() => {
                                setMapVisible(!mapVisible);
                              }}
                            ></Icon>
                          </span>
                        )}
                      </div>
                      {/* <Form.Group widths="equal"> */}
                      {/* <Form.Input
                        label={t("dal")}
                        value={stateStartDate.format("DD-MM-YYYY")}
                        readOnly
                        fluid
                        onChange={() => {}}
                      ></Form.Input>

                      <Form.Input
                        label={t("al")}
                        value={getDataFineEffettiva()}
                        readOnly
                        fluid
                        onChange={() => {}}
                      ></Form.Input> */}
                      {/* </Form.Group> */}

                      {/* <Form.Input
                        icon={
                          <>
                            {coordinateRitrovo !== undefined && (
                              <Icon
                                name="map pin"
                                circular
                                link
                                color="red"
                                onClick={() => {
                                  setMapVisible(!mapVisible);
                                }}
                              ></Icon>
                            )}
                          </>
                        }
                        label={t("ritrovo")}
                        placeholder="Scegli prima le date"
                        value={
                          numGiorniScelti > 0 ? sessioneSelezionata.ritrovo : ""
                        }
                      ></Form.Input> */}
                      {mapVisible && (
                        <Mappa
                          ritrovo={t("ritrovo")}
                          lat={coordinateRitrovo.lat}
                          lng={coordinateRitrovo.lng}
                          zoom={coordinateRitrovo.z}
                        ></Mappa>
                      )}
                      {/* <Form.Group widths="equal"> */}

                      {/* </Form.Group> */}
                    </Form>
                  )}
                  <Prezzo2
                    numGiorniScelti={numGiorniScelti}
                    corso={corso}
                    sessioniCorso={sessioniCorso}
                    sessioneSelezionata={sessioneSelezionata}
                    numAllievi={numAllievi}
                    datiScuola={datiScuola}
                    primaData={stateStartDate}
                  ></Prezzo2>
                  <Segment
                    basic
                    textAlign="center"
                    style={{ paddingLeft: "0px" }}
                  >
                    <Segment
                      inverted
                      className="skiwork-header"
                      textAlign="center"
                    >
                      {t("programma")}
                    </Segment>
                    {programma.map((x) => (
                      <p
                        style={{ marginBottom: "0", fontSize: "18px" }}
                        key={x}
                      >
                        {x}
                      </p>
                    ))}
                  </Segment>
                  <Segment basic textAlign="center">
                    <Button
                      primary
                      onClick={() => {
                        props.onAvantiClick(
                          stateStartDate.format("YYYY-MM-DD"),
                          numGiorniScelti,
                          getNumSessioniEffettive(),
                          numAllievi,
                          listino().prezzo,
                          listino().prezzoPag,
                          listino().prezzoListino
                        );
                      }}
                      size="big"
                    >
                      <Icon name="cart" />
                      {t("avanti")}
                    </Button>
                  </Segment>
                  {windowSize.width <= 600 && (
                    <div
                      style={{
                        marginLeft: "2px",
                        marginTop: "2em",
                      }}
                    >
                      <InfoWeb
                        testi={datiScuola.infoWeb.filter(
                          (x) => x.sezione === 0
                        )}
                        sezione={0}
                      />
                    </div>
                  )}
                </Segment>
              );
            }
          })()}
        </Grid.Column>
      </Grid>
      <div></div>
    </Segment>
  );
}

function ScegliData({ t, ...props }) {
  return (
    <Segment basic>
      <Header as="h3" className="skiwork-titolo">
        <Icon
          className="scelta-data"
          name={props.windowSize.width > 600 ? "arrow left" : "arrow down"}
        ></Icon>
        <Header.Content className="scelta-data">
          {t("data_partenza_corso")}
        </Header.Content>
      </Header>
      <Segment basic>
        <Header as="h4" className="skiwork-titolo">
          LEGENDA
        </Header>
        <div
          style={{
            display: "table-row",
          }}
        >
          <div
            style={{
              verticalAlign: "middle",
              backgroundColor: props.coloriCalendario.start,
              width: "35px",
              height: "35px",
            }}
          >
            {" "}
          </div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              paddingLeft: "14px",
            }}
          >
            {t("date_selezionabili_corso")}
          </div>
        </div>
        <div
          style={{
            display: "table-row",
          }}
        >
          <div
            style={{
              verticalAlign: "middle",
              backgroundColor: props.coloriCalendario.corso,
              width: "35px",
              height: "35px",
            }}
          >
            {" "}
          </div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              paddingLeft: "14px",
            }}
          >
            {t("date_corso")}
          </div>
        </div>
      </Segment>
    </Segment>
  );
}

export default DettaglioCorsoStd;
