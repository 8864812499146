export function validaCodiceFiscale(cf, nome, cognome) {
  var validi, i, s, set1, set2, setpari, setdisp;
  if (cf === "") return false;
  cf = cf.toUpperCase();
  if (cf.length !== 16) return false;
  validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  for (i = 0; i < 16; i++) {
    if (validi.indexOf(cf.charAt(i)) === -1) return false;
  }
  set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
  setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
  s = 0;
  for (i = 1; i <= 13; i += 2)
    s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
  for (i = 0; i <= 14; i += 2)
    s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
  if (s % 26 !== cf.charCodeAt(15) - "A".charCodeAt(0)) return false;

  //Verifico che il codice fiscale sia coerente con il nome e cognome inseriti
  return validateCFWithNameSurname(cf, nome, cognome);
}

function validateCFWithNameSurname(cf, nome, cognome) {
  // Clean and uppercase inputs
  cf = cf.toUpperCase();
  nome = cleanString(nome.toUpperCase());
  cognome = cleanString(cognome.toUpperCase());

  // Get first 6 characters of CF (surname + name part)
  const cfNamePart = cf.substring(0, 6);

  // Helper function to get consonants
  const getConsonants = (str) => str.replace(/[AEIOU\s]/g, "");

  // Helper function to get vowels
  const getVowels = (str) => str.replace(/[^AEIOU]/g, "");

  // Extract surname part (first 3 letters)
  let surnamePart = "";
  const surnameConsonants = getConsonants(cognome);
  const surnameVowels = getVowels(cognome);

  if (surnameConsonants.length >= 3) {
    surnamePart = surnameConsonants.substring(0, 3);
  } else {
    surnamePart =
      surnameConsonants +
      surnameVowels.substring(0, 3 - surnameConsonants.length);
    if (surnamePart.length < 3) {
      surnamePart = surnamePart.padEnd(3, "X");
    }
  }

  // Extract name part (next 3 letters)
  let namePart = "";
  const nameConsonants = getConsonants(nome);
  const nameVowels = getVowels(nome);

  if (nameConsonants.length >= 4) {
    namePart = nameConsonants[0] + nameConsonants[2] + nameConsonants[3];
  } else if (nameConsonants.length === 3) {
    namePart = nameConsonants;
  } else {
    namePart =
      nameConsonants + nameVowels.substring(0, 3 - nameConsonants.length);
    if (namePart.length < 3) {
      namePart = namePart.padEnd(3, "X");
    }
  }

  const expectedPart = surnamePart + namePart;
  return cfNamePart === expectedPart;
}

function cleanString(str) {
  return str.toUpperCase().replace(/[^A-Z]/g, ""); // Remove non-alphabetic characters
}
