import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const RedirectWithTrack = ({ to, push, appData }) => {
  // Verifico se la string "to" che rappresenta una url contiene già dei query parameters
  const hasQueryParams = to.includes("?");

  if (appData.trackId != null) {
    return (
      <Redirect
        to={`${to}${
          hasQueryParams ? "&t=" + appData.trackId : "?t=" + appData.trackId
        }`}
        push={push}
      />
    );
  }

  return <Redirect to={to} push={push} />;
};

RedirectWithTrack.propTypes = {
  to: PropTypes.string.isRequired,
  push: PropTypes.bool,
  appData: PropTypes.shape({
    trackId: PropTypes.string,
  }),
};

function mapStateToProps(state, ownProps) {
  return {
    appData: state.appReducer,
  };
}

export default connect(mapStateToProps)(RedirectWithTrack);
