//GENERAL API ACTIONS
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

//BOOK
export const SET_SLUG = "SET_SLUG";
export const SET_LANG = "SET_LANG";
export const SET_AGENZIA = "SET_AGENZIA";
export const BOOK_SET_CORSO_DETTAGLI = "BOOK_SET_CORSO_DETTAGLI";
export const BOOK_SET_PREFS_PRIVATE = "BOOK_SET_PRIVATE_PREFS";
export const BOOK_SET_PREFS_CORSO = "BOOK_SET_PREFS_CORSO";
export const BOOK_RESET = "BOOK_RESET";

//CART
export const CART_ADD_PRIVATA = "CART_ADD_PRIVATA";
export const CART_REMOVE_PRIVATA = "CART_REMOVE_PRIVATA";
export const CART_ADD_COLLETTIVA = "CART_ADD_COLLETTIVA";
export const CART_REMOVE_COLLETTIVA = "CART_REMOVE_COLLETTIVA";
export const CART_SET_REFERRER = "CART_REFERRER";
export const CART_SUBMIT = "CART_SUBMIT";
export const CART_ADD_FAST = "CART_ADD_FAST";
export const CART_REMOVE_FAST = "CART_REMOVE_FAST";
export const CART_ADD_BUONO = "CART_ADD_BUONO";
export const CART_REMOVE_BUONO = "CART_REMOVE_BUONO";
export const CART_EMPTY = "CART_EMPTY";

//SCUOLA ACTIONS
export const LOAD_SCUOLA_DATA_SUCCESS = "LOAD_SCUOLA_DATA_SUCCESS";
export const LOAD_CORSI_SUCCESS = "LOAD_CORSI_SUCCESS";
export const LOAD_SESSIONICORSI_SUCCESS = "LOAD_SESSIONICORSI_SUCCESS";

export const LOAD_DISPONIBILITA_SUCCESS = "LOAD_DISPONIBILITA_SUCCESS";

export const SCUOLA_CLEAR = "SCUOLA_CLEAR";

//CHECKOUT
export const CHECKOUT_PAGAMENTO_SUCCESS = "CHECKOUT_PAGAMENTO_SUCCESS";

//APP ACTIONS
export const APP_CLEAR = "APP_CLEAR";
export const APP_SET_TRACKID = "APP_SET_TRACKID";
