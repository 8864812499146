import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Container,
  Segment,
  Grid,
  Divider,
  TextArea,
  Form,
  Icon,
  Button,
  Loader,
  Radio,
  Input,
  Label,
  ButtonGroup,
} from "semantic-ui-react";
import TestataPagina from "../common/TestataPagina";
import FormCliente from "../common/FormCliente";
import FormClienteNew from "../common/FormClienteNew";
import FormError from "../common/FormError";
import useWindowSize from "../../utils/useWindowSize";
import TestoPrivacy from "../common/TestoPrivacy";
import TestoVendita from "../common/TestoVendita";
import * as gestionePagamenti from "../../utils/GestionePagamenti";
import * as checkoutApi from "../../api/checkoutApi";
import * as privateApi from "../../api/privateApi";
import * as buoniApi from "../../api/buoniApi";
import * as emailUtils from "../../utils/emailUtils";
import * as signalRService from "../../utils/signalRService";
import * as cartActions from "../../redux/actions/cartActions";
import { useTranslation } from "react-i18next";
import { validaCodiceFiscale } from "../../utils/cfUtils";
import ScalapayFrase from "../common/ScalapayFrase";
import {
  hasThreeConsecutiveChars,
  isValidEuropeanZipCode,
} from "../../utils/strUtils";

function CheckoutMain({
  appData,
  datiScuola,
  cartData,
  loading,
  cartEmpty,
  ...props
}) {
  ///////////////////////////////////////////////
  //STATE
  const [prezzoCollettive, setPrezzoCollettive] = useState({
    prezzo: 0,
    prezzoListino: 0,
    prezzoPag: 0,
    prezzoServizi: 0,
    prezzoServiziPag: 0,
  });
  const [prezzoPrivate, setPrezzoPrivate] = useState({
    prezzo: 0,
    prezzoPag: 0,
  });
  const [prezzoFast, setPrezzoFast] = useState({
    prezzo: 0,
  });
  const [prezzoBuoni, setPrezzoBuoni] = useState({
    prezzo: 0,
  });

  const [usaDatiCliente, setUsaDatiCliente] = useState(true);

  // const [totPagareTransazione, setTotPagareTransazione] = useState(0);
  const [nota, setNota] = useState("");
  const [tipoPagamento, setTipoPagamento] = useState(0);
  const [datiCliente, setDatiCliente] = useState(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return {
        nome: "TEST NOME2",
        cognome: "TEST COGNOME2",
        nazione: appData.lang.toLowerCase() === "it" ? "IT" : "",
        lingua: appData.lang.toUpperCase(),
        linguaInterfaccia: appData.lang.toUpperCase(),
        citta: "TEST CITTA",
        cap: "38037",
        provincia: "TN",
        indirizzo: "VIA TEST, 26",
        telefono: "300000000",
        prefisso: appData.lang.toLowerCase() === "it" ? "+39" : "",
        email: "juricapovilla@gmail.com",
        cf: "",
        dataNascita: "",
        luogoNascita: "",
        tipo: "P",
        codUnivoco: "",
        hotel: "",
      };
    } else {
      return {
        nome: "",
        cognome: "",
        nazione: appData.lang.toLowerCase() === "it" ? "IT" : "",
        lingua: appData.lang.toUpperCase(),
        linguaInterfaccia: appData.lang.toUpperCase(),
        citta: "",
        cap: "",
        provincia: "",
        indirizzo: "",
        prefisso: appData.lang.toLowerCase() === "it" ? "+39" : "",
        telefono: "",
        email: "",
        cf: "",
        dataNascita: "",
        luogoNascita: "",
        tipo: "P",
        codUnivoco: "",
        hotel: "",
      };
    }
  });
  const [errors, setErrors] = useState({
    codice: false,
    nome: false,
    cognome: false,
    nazione: false,
    citta: false,
    cap: false,
    provincia: false,
    indirizzo: false,
    email: false,
    cellulare: false,
    causale: false,
    importo: false,
    privacy: false,
    condizioniVendita: false,
    nota: false,
    cf: false,
    luogoNascita: false,
    dataNascita: false,
    hotel: false,
  });
  const [condizioniVendita, setCondizioniVendita] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [privacy2, setPrivacy2] = useState(false);
  const [privacy3, setPrivacy3] = useState(false);
  const [listaNazioni, setListaNazioni] = useState([]);
  const [listaNazioniDial, setListaNazioniDial] = useState([]);
  const [listaProvince, setListaProvince] = useState([]);
  const [listaLingue, setListaLingue] = useState([]);
  const [listaHotels, setListaHotels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showPrivacy2, setShowPrivacy2] = useState(false);
  const [showPrivacy3, setShowPrivacy3] = useState(false);
  const [showCondizioni, setShowCondizioni] = useState(false);
  const [showAxepta, setShowAxepta] = useState(false);
  const [isFastbookingCheck, setIsFastBookingCheck] = useState(false);
  //const [fastBookingListaOk, setFastBookingListaOk] = useState([]);
  const [axeptaPayload, setAxeptaPayload] = useState("");
  // const [trFinalyzing, setTrFinalyzing] = useState(false);
  const [importoTotaleTransazione, setImportoTotaleTransazione] = useState(0);
  const [idBuonoInUso, setIdBuonoInUso] = useState(0);
  const [codiceBuonoInUso, setCodiceBuonoInUso] = useState("");
  const [importoBuono, setImportoBuono] = useState(0);

  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  const history = useHistory();
  ///////////////////////////////////////////
  // HOOKS
  const windowSize = useWindowSize();

  ///////////////////////////////////////////////
  //EFFECT HOOKS
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    //Calcolo i totali delle collettive
    const pTmpC = { ...prezzoCollettive };

    if (cartData.righeCollettive.length > 0) {
      //prezzo totale
      pTmpC.prezzo = cartData.righeCollettive.reduce(
        (a, { prezzo, numAllievi }) => a + prezzo * numAllievi,
        0
      );
      pTmpC.prezzoListino = cartData.righeCollettive.reduce(
        (a, { prezzoListino, numAllievi }) => a + prezzoListino * numAllievi,
        0
      );
      //prezzo pagare
      pTmpC.prezzoPag = cartData.righeCollettive.reduce(
        (a, { prezzoPag, numAllievi }) => a + prezzoPag * numAllievi,
        0
      );

      //Prezzo eventuali servizi
      for (let i = 0; i < cartData.righeCollettive.length; i++) {
        for (
          let a = 0;
          a < cartData.righeCollettive[i].serviziAllievo.length;
          a++
        ) {
          for (
            let s = 0;
            s < cartData.righeCollettive[i].serviziAllievo[a].length;
            s++
          ) {
            if (
              cartData.righeCollettive[i].prezzo ===
              cartData.righeCollettive[i].prezzoPag
            ) {
              if (
                cartData.righeCollettive[i].serviziAllievo[a][s].prezzoAlGiorno
              ) {
                pTmpC.prezzoServiziPag +=
                  cartData.righeCollettive[i].serviziAllievo[a][s].prezzo *
                  cartData.righeCollettive[i].numGiorniScelti;
              } else {
                pTmpC.prezzoServiziPag +=
                  cartData.righeCollettive[i].serviziAllievo[a][s].prezzo;
              }
            }

            if (
              cartData.righeCollettive[i].serviziAllievo[a][s].prezzoAlGiorno
            ) {
              pTmpC.prezzoServizi +=
                cartData.righeCollettive[i].serviziAllievo[a][s].prezzo *
                cartData.righeCollettive[i].numGiorniScelti;
            } else {
              pTmpC.prezzoServizi +=
                cartData.righeCollettive[i].serviziAllievo[a][s].prezzo;
            }
          }
        }
      }
    }
    setPrezzoCollettive(pTmpC);

    //imposto la modalità rooming list
    if (cartData.righeCollettive.length > 0) {
      if (cartData.righeCollettive[0].roomingList) {
        setUsaDatiCliente(false);
      }
    }

    //Calcolo i totali delle private
    const pTmpP = { ...prezzoPrivate };
    if (cartData.righePrivate.length > 0) {
      pTmpP.prezzo = 0;
      pTmpP.prezzoPag = 0;
      pTmpP.prezzoListino = 0;

      for (let i = 0; i < cartData.righePrivate.length; i++) {
        pTmpP.prezzo += cartData.righePrivate[i].prezzo;
        pTmpP.prezzoPag += cartData.righePrivate[i].prezzoPag;
        pTmpP.prezzoListino += cartData.righePrivate[i].prezzoListino;
      }
      // pTmpP.prezzo = cartData.righePrivate.reduce((a, b) => {
      //   return a.prezzo + b.prezzo;
      // });

      // pTmpP.prezzoPag = cartData.righePrivate.reduce((a, b) => {
      //   return a.prezzoPag + b.prezzoPag;
      // });
    }
    setPrezzoPrivate(pTmpP);

    //Totali FastBooking
    const pTmpF = { ...prezzoFast };
    if (cartData.righeFastBooking.length > 0) {
      pTmpF.prezzo = cartData.righeFastBooking.reduce(
        (a, { prezzi, numPersone }) => a + prezzi[numPersone - 1],
        0
      );
      pTmpF.prezzoListino = cartData.righeFastBooking.reduce(
        (a, { prezziListino, numPersone }) => a + prezziListino[numPersone - 1],
        0
      );
    }
    setPrezzoFast(pTmpF);

    //totali Buoni
    const pTmpB = { ...prezzoBuoni };
    if (cartData.righeBuoni.length > 0) {
      pTmpB.prezzo = cartData.righeBuoni.reduce(
        (a, { valore }) => a + valore,
        0
      );
    }
    setPrezzoBuoni(pTmpB);

    if (pTmpC.prezzoPag !== pTmpC.prezzo || pTmpP.prezzoPag !== pTmpP.prezzo) {
      setImportoTotaleTransazione(
        pTmpP.prezzoPag +
          pTmpC.prezzoPag +
          pTmpC.prezzoServiziPag +
          pTmpF.prezzo +
          pTmpB.prezzo
      );
    } else {
      setImportoTotaleTransazione(
        pTmpP.prezzo +
          pTmpC.prezzo +
          pTmpC.prezzoServizi +
          pTmpF.prezzo +
          pTmpB.prezzo
      );
    }
  }, []);

  useEffect(() => {
    if (showAxepta && axeptaPayload !== "") {
      gestionePagamenti.EffettuaPagamento(axeptaPayload);
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [showAxepta, axeptaPayload]);

  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
    }
  }, [appData.lang]);

  useEffect(() => {
    if (datiScuola.richiestaHotel && datiScuola.hotels.length > 0) {
      setListaHotels([
        { key: "", value: "", text: t("Seleziona_Hotel") },
        { key: "_X", value: "_X", text: t("No_Hotel") },
        ...datiScuola.hotels.map((h) => {
          return { key: h, value: h, text: h };
        }),
      ]);
    }

    if (datiScuola.hasOwnProperty("listaNazioni") && datiScuola !== undefined) {
      setListaNazioni(
        datiScuola.listaNazioni.map((n) => {
          return { key: n.iso, value: n.iso, text: n.nazione };
        })
      );

      setListaProvince(
        datiScuola.listaProvince.map((n) => {
          return { key: n.sigla, value: n.sigla, text: n.nome };
        })
      );

      setListaLingue(
        datiScuola.lingue.map((n) => {
          return {
            key: n.idskiwork,
            value: n.codiceIso,
            text: n.linguaParlata,
          };
        })
      );

      setListaNazioniDial(
        [...datiScuola.listaNazioni]
          .sort((a, b) => {
            const nameA = a.iso.toUpperCase(); // ignore upper and lowercase
            const nameB = b.iso.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
          .filter((n) => {
            return n.dialCode?.length >= 2;
          })
          .map((n) => {
            return {
              key: n.iso,
              value: n.dialCode,
              text: n.iso + " " + n.dialCode,
              flag: n.iso.toLowerCase(),
            };
          })
      );
    }

    if (datiScuola.hasOwnProperty("tipoBanca")) {
      if (datiScuola.tipoBanca === 4) {
        const script = document.createElement("script");
        if (datiScuola.banca4 === "1") {
          script.src = "https://pay-test.axepta.it/sdk/axepta-pg-redirect.js";
        } else {
          script.src = "https://pay-test.axepta.it/sdk/axepta-pg-sdk.js";
        }
        script.async = true;
        document.body.appendChild(script);
      }
    }
  }, [datiScuola]);

  //////////////////////////////////////////////
  //HANDLERS
  function handleFieldChange(ev, data) {
    if (data.name === "cap") {
      if (data.value.length > 6) data.value = data.value.substring(0, 6);
    }

    if (data.name === "telefono") {
      const re = /^[0-9\b]+$/;
      if (data.value !== "" && !re.test(data.value)) {
        data.value = datiCliente.telefono;
      }
    }

    if (datiCliente.tipo === "A") {
      if (data.name === "cf") {
        const re = /^[0-9\b]{1,11}$/;
        if (data.value !== "" && !re.test(data.value)) {
          data.value = datiCliente.cf;
        }
      }

      if (data.name === "codUnivoco") {
        const re = /^[a-zA-Z0-9]{1,7}$/;
        if (data.value !== "" && !re.test(data.value)) {
          data.value = datiCliente.codUnivoco;
        }
      }
    }

    if (data.name === "nazione") {
      const nation = listaNazioniDial.find((x) => x.key === data.value);
      datiCliente.prefisso = nation.value;
    }

    if (data.name === "tipo") {
      datiCliente.cf = "";
      datiCliente.codUnivoco = "";
    }

    let datiTmp = { ...datiCliente };
    datiTmp[data.name] = data.value;
    setErrors({ ...errors, [data.name]: false });
    setDatiCliente(datiTmp);
  }
  function handleNotaChange(ev, data) {
    if (data.value.length < 250) {
      setNota(data.value);
      setErrors({ ...errors, nota: false });
    }
  }
  function handlePrivacyChange(ev, data) {
    setPrivacy(data.checked);
    setErrors({ ...errors, privacy: !data.checked });
  }
  function handlePrivacy2Change(ev, data) {
    setPrivacy2(data.checked);
  }
  function handlePrivacy3Change(ev, data) {
    setPrivacy3(data.checked);
  }
  function handleCondizioniVenditaChange(ev, data) {
    setCondizioniVendita(data.checked);
    setErrors({ ...errors, condizioniVendita: !data.checked });
  }
  function handleAckResult(result) {
    if (result.errorMessage !== "") {
      alert(result.errorMessage);
      setIsFastBookingCheck(false);
      return;
    }

    setIsFastBookingCheck(false);
    handleCheckout(result.datiTransazioni);
  }
  async function handleCodiceBuono() {
    setIsLoading(true);
    var result = await buoniApi.GetInfoBuono(datiScuola.id, codiceBuonoInUso);

    if (result === null) {
      setIsLoading(false);
      alert("Codice non valido, scaduto o già utilizzato");
      setCodiceBuonoInUso("");
      return;
    }

    console.log(importoTotaleTransazione);
    if (result.importo > importoTotaleTransazione) {
      setIsLoading(false);
      alert(
        "Il buono non può essere utilizzato, perché il valore del buono è superiore al totale della transazione"
      );
      return;
    }
    setIdBuonoInUso(result.id);
    setImportoBuono(result.importo);

    setIsLoading(false);
  }
  const handleTipoPagamentoChange = (e, { value }) => {
    setTipoPagamento(value);
  };

  //Verifica gli errori formali sulla form e
  //se presenti verifica le richieste fastbooking con il server
  async function checkDati() {
    let checkOk = true;
    var newErrors = { ...errors };

    if (usaDatiCliente === true) {
      if (datiCliente.nome.length < 2 || datiCliente.nome > 50) {
        newErrors.nome = true;
        checkOk = false;
      }
      if (datiCliente.cognome.length < 2 || datiCliente.cognome > 50) {
        newErrors.cognome = true;
        checkOk = false;
      }
      if (datiCliente.nazione.length !== 2) {
        newErrors.nazione = true;
        checkOk = false;
      }
      if (datiCliente.citta.length < 3 || datiCliente.citta.length > 50) {
        newErrors.citta = true;
        checkOk = false;
      }
      if (hasThreeConsecutiveChars(datiCliente.citta)) {
        newErrors.citta = true;
        checkOk = false;
      }
      //INDIRIZZO
      if (
        datiCliente.indirizzo.length < 5 ||
        datiCliente.indirizzo.length > 50
      ) {
        newErrors.indirizzo = true;
        checkOk = false;
      }
      if (hasThreeConsecutiveChars(datiCliente.indirizzo)) {
        newErrors.indirizzo = true;
        checkOk = false;
      }

      //CAP
      if (!isValidEuropeanZipCode(datiCliente.cap, datiCliente.nazione)) {
        newErrors.cap = true;
        checkOk = false;
      } else {
        newErrors.cap = false;
      }

      //CONTROLLI PER CLIENTI ITALIANI
      if (datiCliente.nazione === "IT") {
        //PROVINCIA
        if (datiCliente.provincia === null) datiCliente.provincia = "";
        if (datiCliente.provincia.length !== 2) {
          newErrors.provincia = true;
          datiCliente.provincia = "";
          checkOk = false;
        }

        //CF
        newErrors.cf = false;
        if (
          !validaCodiceFiscale(
            datiCliente.cf,
            datiCliente.nome,
            datiCliente.cognome
          )
        ) {
          newErrors.cf = true;
          checkOk = false;
        }
      } else {
        datiCliente.cf = "";
        datiCliente.provincia = "";
      }

      //AZIENDE
      if (datiCliente.tipo === "A") {
        //verifico la validità della partita iva (11 caratteri)
        const regexPIVA = /^\d{11}$/;
        if (!regexPIVA.test(datiCliente.cf)) {
          newErrors.cf = true;
          checkOk = false;
        }

        //verifico la validità del codice univoco se Italiano
        if (datiCliente.nazione === "IT") {
          const regexCodUnivoco = /^[a-zA-Z0-9]{7}$/;
          if (!regexCodUnivoco.test(datiCliente.codUnivoco)) {
            newErrors.codUnivoco = true;
            checkOk = false;
          }
        } else {
          datiCliente.codUnivoco = "";
        }
      }

      //EMAIL
      if (
        datiCliente.email.length < 2 ||
        datiCliente.email.length > 50 ||
        !emailUtils.ValidateEmail(datiCliente.email)
      ) {
        newErrors.email = true;
        checkOk = false;
      }

      //TELEFONO
      if (datiCliente.telefono.length < 5 || datiCliente.telefono.length > 15) {
        newErrors.telefono = true;
        checkOk = false;
      }

      //PRIVACY
      if (privacy === false) {
        newErrors.privacy = true;
        checkOk = false;
      }

      //CONDIZIONI VENDITA
      if (condizioniVendita === false) {
        newErrors.condizioniVendita = true;
        checkOk = false;
      }

      //NOTA
      if (nota.length > 250) {
        newErrors.nota = true;
        checkOk = false;
      }
    }

    //RICHIESTA HOTEL
    if (datiScuola.richiestaHotel && datiScuola.hotels.length > 0) {
      if (datiCliente.hotel === "") {
        newErrors.hotel = true;
        checkOk = false;
      }
    }

    setErrors(newErrors);
    if (!checkOk) return checkOk;

    //Inizializzo il check in tempo reale per il fast booking
    if (cartData.righeFastBooking.length > 0) {
      const signalR = await signalRService.setUpSignalRConnectionAck(
        handleAckResult
      );

      const listaOre = [];
      for (let i = 0; i < cartData.righeFastBooking.length; i++) {
        listaOre.push({
          data: cartData.righeFastBooking[i].data,
          dalle: cartData.righeFastBooking[i].dalle,
          alle: cartData.righeFastBooking[i].alle,
          idZona: cartData.righeFastBooking[i].idZona,
          idSpecialita: cartData.righeFastBooking[i].idSpecialita,
          idMaestro: cartData.righeFastBooking[i].idMaestro,
          lingua: cartData.righeFastBooking[i].lingua,
          numPersone: cartData.righeFastBooking[i].numPersone,
          prezzo:
            cartData.righeFastBooking[i].prezzi[
              cartData.righeFastBooking[i].numPersone - 1
            ],
          idOccupazione: 0,
        });
      }

      privateApi.RequestAckFastBooking(
        datiScuola.id,
        datiScuola.codiceScuola,
        signalR.connectionId,
        listaOre
      );
      setIsFastBookingCheck(true);

      return;
    }

    handleCheckout();
  }

  // async function checkDati() {
  //   let checkOk = true;
  //   var newErrors = { ...errors };

  //   if (usaDatiCliente === true) {
  //     if (datiCliente.nome.length < 2 || datiCliente.nome > 50) {
  //       newErrors.nome = true;
  //       checkOk = false;
  //     }
  //     if (datiCliente.cognome.length < 2 || datiCliente.cognome > 50) {
  //       newErrors.cognome = true;
  //       checkOk = false;
  //     }
  //     if (datiCliente.nazione.length !== 2) {
  //       newErrors.nazione = true;
  //       checkOk = false;
  //     }
  //     if (datiCliente.citta.length < 3 || datiCliente.citta.length > 50) {
  //       newErrors.citta = true;
  //       checkOk = false;
  //     }
  //     if (
  //       datiCliente.indirizzo.length < 5 ||
  //       datiCliente.indirizzo.length > 50
  //     ) {
  //       newErrors.indirizzo = true;
  //       checkOk = false;
  //     }
  //     //CAP
  //     if (datiCliente.nazione === "IT") {
  //       const regexCAP_IT = /^\d{5}$/;
  //       if (
  //         datiCliente.cap.length !== 5 ||
  //         datiCliente.cap === "00000" ||
  //         !regexCAP_IT.test(datiCliente.cap)
  //       ) {
  //         newErrors.cap = true;
  //         checkOk = false;
  //       } else {
  //         if (datiCliente.cap.length < 3 || datiCliente.cap.length > 15) {
  //           datiCliente.cap = "00000";
  //         }
  //       }
  //     }

  //     //PROVINCIA
  //     if (datiCliente.nazione === "IT") {
  //       if (datiCliente.provincia === null) datiCliente.provincia = "";
  //       if (datiCliente.provincia.length !== 2) {
  //         newErrors.provincia = true;
  //         datiCliente.provincia = "";
  //         checkOk = false;
  //       }
  //     }

  //     if (datiCliente.nazione.length > 0) {
  //       if (datiCliente.nazione === "IT") {
  //         newErrors.cf = false;
  //         if (!validaCodiceFiscale(datiCliente.cf)) {
  //           newErrors.cf = true;
  //           checkOk = false;
  //         }
  //       } else {
  //         datiCliente.cf = "";
  //       }
  //     }

  //     if (datiCliente.tipo === "A") {
  //       //verifico la validità della partita iva (11 caratteri)
  //       const regexPIVA = /^\d{11}$/;
  //       if (!regexPIVA.test(datiCliente.cf)) {
  //         newErrors.cf = true;
  //         checkOk = false;
  //       }

  //       //verifico la validità del codice univoco se Italiano
  //       if (datiCliente.nazione === "IT") {
  //         const regexCodUnivoco = /^[a-zA-Z0-9]{7}$/;
  //         if (!regexCodUnivoco.test(datiCliente.codUnivoco)) {
  //           newErrors.codUnivoco = true;
  //           checkOk = false;
  //         }
  //       } else {
  //         datiCliente.codUnivoco = "";
  //       }
  //     }

  //     if (
  //       datiCliente.email.length < 2 ||
  //       datiCliente.email.length > 50 ||
  //       !emailUtils.ValidateEmail(datiCliente.email)
  //     ) {
  //       newErrors.email = true;
  //       checkOk = false;
  //     }
  //     if (datiCliente.telefono.length < 5 || datiCliente.telefono.length > 15) {
  //       newErrors.telefono = true;
  //       checkOk = false;
  //     }
  //     if (privacy === false) {
  //       newErrors.privacy = true;
  //       checkOk = false;
  //     }
  //     if (condizioniVendita === false) {
  //       newErrors.condizioniVendita = true;
  //       checkOk = false;
  //     }

  //     if (nota.length > 250) {
  //       newErrors.nota = true;
  //       checkOk = false;
  //     }
  //   }

  //   if (datiScuola.richiestaHotel && datiScuola.hotels.length > 0) {
  //     if (datiCliente.hotel === "") {
  //       newErrors.hotel = true;
  //       checkOk = false;
  //     }
  //   }

  //   setErrors(newErrors);
  //   if (!checkOk) return checkOk;

  //   //Inizializzo il check in tempo reale per il fast booking
  //   if (cartData.righeFastBooking.length > 0) {
  //     const signalR = await signalRService.setUpSignalRConnectionAck(
  //       handleAckResult
  //     );

  //     const listaOre = [];
  //     for (let i = 0; i < cartData.righeFastBooking.length; i++) {
  //       listaOre.push({
  //         data: cartData.righeFastBooking[i].data,
  //         dalle: cartData.righeFastBooking[i].dalle,
  //         alle: cartData.righeFastBooking[i].alle,
  //         idZona: cartData.righeFastBooking[i].idZona,
  //         idSpecialita: cartData.righeFastBooking[i].idSpecialita,
  //         idMaestro: cartData.righeFastBooking[i].idMaestro,
  //         lingua: cartData.righeFastBooking[i].lingua,
  //         numPersone: cartData.righeFastBooking[i].numPersone,
  //         prezzo:
  //           cartData.righeFastBooking[i].prezzi[
  //             cartData.righeFastBooking[i].numPersone - 1
  //           ],
  //         idOccupazione: 0,
  //       });
  //     }

  //     privateApi.RequestAckFastBooking(
  //       datiScuola.id,
  //       datiScuola.codiceScuola,
  //       signalR.connectionId,
  //       listaOre
  //     );
  //     setIsFastBookingCheck(true);

  //     return;
  //   }

  //   //Verifico se ho attivato il Codice Fiscale obbligatorio
  //   // if (datiScuola.usaCodiceFiscale) {
  //   //   if (datiCliente.nazione.length > 0) {
  //   //     if (datiCliente.nazione === "IT") {
  //   //       newErrors.cf = false;
  //   //       if (!validaCodiceFiscale(datiCliente.cf)) {
  //   //         newErrors.cf = true;
  //   //         checkOk = false;
  //   //       }
  //   //     } else {
  //   //       newErrors.luogoNascita = false;
  //   //       newErrors.dataNascita = false;
  //   //       if (datiCliente.luogoNascita.length < 3) {
  //   //         newErrors.luogoNascita = true;
  //   //         checkOk = false;
  //   //       }
  //   //       if (
  //   //         datiCliente.dataNascita === "" ||
  //   //         datiCliente.dataNascita === null ||
  //   //         datiCliente.dataNascita === undefined
  //   //       ) {
  //   //         newErrors.dataNascita = true;
  //   //         checkOk = false;
  //   //       }
  //   //     }
  //   //   }
  //   // }

  //   handleCheckout();
  // }
  async function handleCheckout(datiFastBooking) {
    let agenteMaestro = false;
    let nomeAgenzia = "";

    if (appData.agenzia !== undefined) {
      if (appData.agenzia.tipo === "agente") agenteMaestro = true;
      nomeAgenzia = appData.agenzia.nomeAgenzia;
      if (usaDatiCliente === false) {
        datiCliente.usaAnagraficaAgenzia = true;
      }
    }

    setIsLoading(true);

    if (datiCliente.nazione !== "IT") {
      datiCliente.provincia = "";
    }

    var result = await checkoutApi.CheckoutCarrello({
      idScuola: datiScuola.id,
      codScuola: datiScuola.codiceScuola,
      note: nota,
      idLingua: datiScuola.idLinguaInUso,
      ipAddress: "",
      isAgenteMaestro: agenteMaestro,
      nomeAgenzia: nomeAgenzia,
      idAgenzia: appData.agenzia === undefined ? 0 : appData.agenzia.idAgenzia,
      idBuono: idBuonoInUso,
      UsaAnagraficaAgenzia: !usaDatiCliente,
      numBanca: tipoPagamento > 0 ? 2 : 1,
      trackId: appData.trackId === undefined ? null : appData.trackId,
      cliente: {
        idscuola: datiScuola.id,
        codiceScuola: datiScuola.codiceScuola,
        consensoPrivacy2: privacy2,
        consensoPrivacy3: privacy3,
        ...datiCliente,
      },
      corsiCollettivi: cartData.righeCollettive.map((r) => {
        return {
          idCorso: r.id,
          idCorsoSkiwork: r.idCorso,
          startDate: r.startDate,
          listaDate: r.listaDate,
          numGiorni: r.numGiorniScelti,
          numSessioni: r.numSessioni,
          prezzo: r.prezzo,
          prezzoPag: r.prezzoPag,
          allievi: r.allievi.map((a, idx) => {
            return {
              nome: a.nome,
              cognome: a.cognome,
              sesso: a.sesso,
              anno: a.anno,
              livello: a.livello,
              dataNascita: a.dataNascita,
              luogoNascita: a.luogoNascita,
              servizi: r.serviziAllievo[idx].map((s) => {
                return {
                  idServizio: s.id,
                  descrizione: s.descrizione,
                  prezzo: s.prezzoAlGiorno
                    ? s.prezzo * r.numGiorniScelti
                    : s.prezzo,
                  prezzoPag: s.prezzoAlGiorno
                    ? s.prezzo * r.numGiorniScelti
                    : s.prezzo,
                };
              }),
            };
          }),
        };
      }),
      lezioniPrivate: cartData.righePrivate.map((r) => {
        return {
          idLingua: r.idLingua,
          lingua: r.lingua,
          sesso: r.idSesso,
          idSpecialita: r.idSpecialita,
          descSpecialita: r.specialita,
          idMaestro: r.idMaestro,
          data: r.data,
          dalle: r.dalle,
          alle: r.alle,
          idFascia: r.fascia,
          numAllievi: r.numAllievi,
          ore: r.ore,
          prezzo: r.prezzo,
          prezzoPag: r.prezzoPag,
          pacchetto: r.pacchetto,
          allievi: r.datiAllievi.map((a) => {
            return {
              nome: a.nome,
              cognome: a.cognome,
              livello: a.livello === "" ? 0 : a.livello,
              anno: a.anno === "" ? 0 : a.anno,
              sesso: a.sesso,
            };
          }),
        };
      }),
      //todo verificare corrispondenza idx
      fastBooking:
        datiFastBooking !== undefined
          ? datiFastBooking.map((f, idx) => {
              return {
                importo: f.prezzo,
                nota: nota,
                idScuola: datiScuola.id,
                idOccupazione: f.idOccupazione,
                numAllievi: f.numPersone,
                allievi: cartData.righeFastBooking[idx].allievi.map(
                  (a, idx) => {
                    return {
                      nome: a.nome,
                      cognome: a.cognome,
                      sesso: a.sesso,
                      anno: a.anno === "" ? 0 : a.anno,
                      livello: a.livello === "" ? 0 : a.livello,
                    };
                  }
                ),
              };
            })
          : [],

      buoni: cartData.righeBuoni.map((r) => {
        return {
          idtipoBuono: r.idtipoBuono,
          valore: r.valore,
          regalo: r.regalo,
        };
      }),
    });

    if (result.result === null) {
      alert("problemi nella richiesta di pamento, riprovare più tardi");
      setIsLoading(false);
      return;
    } else {
      console.log(result);
    }

    // debugger;
    if (
      datiScuola.codiceScuola === "DEMOSKIW" ||
      datiScuola.codiceScuola === "PROVADC2"
    ) {
      cartEmpty();
      await checkoutApi.RichiediAckForzato(
        datiScuola.codiceScuola,
        result.payload3
      );
      history.push(
        `/${appData.lang}/${appData.slug}/Checkout/OK?Order_id=${result.payload1}`
      );
      return;
    }

    if (!agenteMaestro) {
      if (datiScuola.tipoBanca !== 4) {
        gestionePagamenti.EffettuaPagamento(result);
        cartEmpty();
      } else {
        setIsLoading(false);
        setAxeptaPayload(result);
        setShowAxepta(true);

        //Abilito il client signalR per ricevere il buonfine
        const signalRAxepta = await signalRService.setUpSignalRConnectionAxepta(
          onAckAxepta,
          onNackAxepta,
          onOkAxepta
        );
        const res = checkoutApi.SetAxeptaConnectionId(
          datiScuola.codiceScuola,
          datiScuola.id,
          result.payload4,
          signalRAxepta.connectionId
        );
      }
    } else {
      cartEmpty();
      history.push(
        `/${appData.lang}/${appData.slug}/Checkout/OK?Order_id=${result.payload1}`
      );
    }
  }
  function onAckAxepta(orderId) {
    cartEmpty();
    history.push(
      `/${appData.lang}/${appData.slug}/checkout/ok?order_id=${orderId}`
    );
  }
  function onNackAxepta() {
    history.push(`/${appData.lang}/${appData.slug}/checkout/ko`);
  }
  function onOkAxepta() {
    //setTrFinalyzing(true);
    setIsLoading(true);
  }

  const getTotalePagare = () => {
    if (
      prezzoCollettive.prezzoPag !== prezzoCollettive.prezzo ||
      prezzoPrivate.prezzoPag !== prezzoPrivate.prezzo
    ) {
      //se tutti i corsi sono a saldo, allora sommo al totale anche i servizi
      return (
        prezzoPrivate.prezzoPag +
        prezzoCollettive.prezzoPag +
        prezzoCollettive.prezzoServiziPag +
        prezzoFast.prezzo +
        prezzoBuoni.prezzo
      );
    } else {
      return (
        prezzoPrivate.prezzo +
        prezzoCollettive.prezzo +
        prezzoCollettive.prezzoServizi +
        prezzoFast.prezzo +
        prezzoBuoni.prezzo
      );
    }
  };
  /////////////////////////////////////////////
  //RENDER FUNCTIONS
  function renderTotale() {
    const collettive = prezzoCollettive.prezzo > 0 && (
      <>
        <Divider />
        <label style={{ textAlign: "right", marginBottom: "7px" }}>
          {t("corsi_collettivi")}
        </label>

        {prezzoCollettive.prezzo < prezzoCollettive.prezzoListino && (
          <>
            <div className="riga-prezzo">
              <span>{t("prezzo_listino")}</span>

              <span className="prezzo_parziale">
                {datiScuola.valuta} {prezzoCollettive.prezzoListino.toFixed(2)}
              </span>
            </div>
            <div className="riga-prezzo">
              <span>{t("sconto_applicato")}</span>
              <span className="prezzo_parziale">
                - {datiScuola.valuta}{" "}
                {(
                  prezzoCollettive.prezzoListino - prezzoCollettive.prezzo
                ).toFixed(2)}
              </span>
            </div>
          </>
        )}
        {prezzoCollettive.prezzoServizi > 0 && (
          <div className="riga-prezzo">
            <span>{t("servizi")}</span>

            <span className="prezzo_parziale">
              {datiScuola.valuta} {prezzoCollettive.prezzoServizi.toFixed(2)}
            </span>
          </div>
        )}
        <div className="riga-prezzo">
          <span className="prezzo_parziale label-prezzo">
            {t("totale")} {t("corsi_collettivi")}
          </span>

          <span className="prezzo_parziale">
            {datiScuola.valuta}{" "}
            {(prezzoCollettive.prezzo + prezzoCollettive.prezzoServizi).toFixed(
              2
            )}
          </span>
        </div>
      </>
    );

    const lprivate = prezzoPrivate.prezzo > 0 && (
      <>
        <Divider />{" "}
        <label style={{ textAlign: "right", marginBottom: "7px" }}>
          {t("lezioni_private")}
        </label>
        {prezzoPrivate.prezzo < prezzoPrivate.prezzoListino && (
          <>
            <div className="riga-prezzo">
              <span>{t("prezzo_listino")}</span>

              <span className="prezzo_parziale">
                {datiScuola.valuta} {prezzoPrivate.prezzoListino.toFixed(2)}
              </span>
            </div>
            <div className="riga-prezzo">
              <span>{t("sconto_applicato")}</span>
              <span className="prezzo_parziale">
                - {datiScuola.valuta}{" "}
                {(prezzoPrivate.prezzoListino - prezzoPrivate.prezzo).toFixed(
                  2
                )}
              </span>
            </div>
          </>
        )}
        <div className="riga-prezzo">
          <span className="prezzo_parziale label-prezzo">
            {t("totale")} {t("lezioni_private")}
          </span>

          <span className="prezzo_parziale">
            {datiScuola.valuta} {prezzoPrivate.prezzo.toFixed(2)}
          </span>
        </div>
      </>
    );

    const fast = prezzoFast.prezzo > 0 && (
      <>
        <Divider />
        <label style={{ textAlign: "right", marginBottom: "7px" }}>
          {t("lezioni_private")} *FAST BOOKING*
        </label>
        {prezzoFast.prezzo < prezzoFast.prezzoListino && (
          <>
            <div className="riga-prezzo">
              <span>{t("prezzo_listino")}</span>

              <span className="prezzo_parziale">
                {datiScuola.valuta} {prezzoFast.prezzoListino.toFixed(2)}
              </span>
            </div>
            <div className="riga-prezzo">
              <span>{t("sconto_applicato")}</span>
              <span className="prezzo_parziale">
                - {datiScuola.valuta}{" "}
                {(prezzoFast.prezzoListino - prezzoFast.prezzo).toFixed(2)}
              </span>
            </div>
          </>
        )}
        <div className="riga-prezzo">
          <span className="prezzo_parziale label-prezzo">
            {t("totale")} *FAST BOOKING*
          </span>

          <span className="prezzo_parziale">
            {datiScuola.valuta} {prezzoFast.prezzo.toFixed(2)}
          </span>
        </div>
        {/* <p style={{ textAlign: "right", marginBottom: "0px" }}>
          {t("lezioni_private")} *FAST BOOKING*
        </p>
        <p style={{ textAlign: "right" }} className="prezzo_parziale">
          {datiScuola.valuta} {prezzoFast.prezzo.toFixed(2)}
        </p> */}
      </>
    );

    const buoni = prezzoBuoni.prezzo > 0 && (
      <>
        <Divider />
        <label style={{ textAlign: "right", marginBottom: "7px" }}>BUONI</label>
        <div className="riga-prezzo">
          <span className="prezzo_parziale label-prezzo">
            {t("totale")} Buoni
          </span>

          <span className="prezzo_parziale">
            {datiScuola.valuta} {prezzoBuoni.prezzo.toFixed(2)}
          </span>
        </div>
        {/* {" "}
        <p style={{ textAlign: "right", marginBottom: "0px" }}>BUONI</p>
        <p style={{ textAlign: "right" }} className="prezzo_parziale">
          {datiScuola.valuta} {prezzoBuoni.prezzo.toFixed(2)}
        </p> */}
      </>
    );

    let totale = 0;
    let totPagare = 0;
    //Verifico se almeno una delle tipologie è in acconto (anche parziale)
    if (
      prezzoCollettive.prezzoPag !== prezzoCollettive.prezzo ||
      prezzoPrivate.prezzoPag !== prezzoPrivate.prezzo
    ) {
      //se tutti i corsi sono a saldo, allora sommo al totale anche i servizi
      totPagare =
        prezzoPrivate.prezzoPag +
        prezzoCollettive.prezzoPag +
        prezzoCollettive.prezzoServiziPag +
        prezzoFast.prezzo +
        prezzoBuoni.prezzo;
      totale = (
        <>
          <div className="riga-prezzo">
            <span className="prezzo_totale label-prezzo">{t("totale")}</span>

            <span className="prezzo_totale">
              {datiScuola.valuta}{" "}
              {(
                prezzoPrivate.prezzo +
                prezzoCollettive.prezzo +
                prezzoCollettive.prezzoServizi +
                prezzoFast.prezzo +
                prezzoBuoni.prezzo
              ).toFixed(2)}
            </span>
          </div>
          <Divider />
          <div className="riga-prezzo">
            <span className="prezzo_parziale">{t("acconto_da_pagare")}</span>
            <span className="prezzo_parziale">
              {" "}
              {datiScuola.valuta}{" "}
              {(
                prezzoPrivate.prezzoPag +
                prezzoCollettive.prezzoPag +
                prezzoCollettive.prezzoServiziPag +
                prezzoFast.prezzo +
                prezzoBuoni.prezzo
              ).toFixed(2)}
            </span>
          </div>
          <div className="riga-prezzo">
            <span className="prezzo_parziale">{t("saldo")}</span>
            <span className="prezzo_parziale">
              {" "}
              {datiScuola.valuta}{" "}
              {(
                prezzoPrivate.prezzo +
                prezzoCollettive.prezzo +
                prezzoCollettive.prezzoServizi +
                prezzoFast.prezzo +
                prezzoBuoni.prezzo -
                (prezzoPrivate.prezzoPag +
                  prezzoCollettive.prezzoPag +
                  prezzoCollettive.prezzoServiziPag +
                  prezzoFast.prezzo +
                  prezzoBuoni.prezzo)
              ).toFixed(2)}
            </span>
          </div>
          {/* <label style={{ textAlign: "right" }}>{t("totale")}</label>
          <p style={{ textAlign: "right" }} className="prezzo_totale">
            {datiScuola.valuta}{" "}
            {(
              prezzoPrivate.prezzo +
              prezzoCollettive.prezzo +
              prezzoCollettive.prezzoServizi +
              prezzoBuoni.prezzo
            ).toFixed(2)}
          </p>
          <label style={{ textAlign: "right" }}>{t("acconto")}</label>
          <p style={{ textAlign: "right" }} className="prezzo_parziale">
            {datiScuola.valuta}{" "}
            {(
              prezzoPrivate.prezzoPag +
              prezzoCollettive.prezzoPag +
              prezzoCollettive.prezzoServiziPag +
              prezzoFast.prezzo +
              prezzoBuoni.prezzo
            ).toFixed(2)}
          </p> */}
        </>
      );
    } else {
      totPagare =
        prezzoPrivate.prezzo +
        prezzoCollettive.prezzo +
        prezzoCollettive.prezzoServizi +
        prezzoFast.prezzo +
        prezzoBuoni.prezzo;
      totale = (
        <div className="riga-prezzo">
          <span className="prezzo_totale">{t("totale")}</span>
          <span className="prezzo_totale">
            {datiScuola.valuta}{" "}
            {(
              prezzoPrivate.prezzo +
              prezzoCollettive.prezzo +
              prezzoCollettive.prezzoServizi +
              prezzoFast.prezzo +
              prezzoBuoni.prezzo
            ).toFixed(2)}
          </span>
          {/* <label style={{ textAlign: "right" }}>{t("totale")}</label>
          <p style={{ textAlign: "right" }} className="prezzo_totale">
            {datiScuola.valuta}{" "}
            {(
              prezzoPrivate.prezzo +
              prezzoCollettive.prezzo +
              prezzoCollettive.prezzoServizi +
              prezzoFast.prezzo +
              prezzoBuoni.prezzo
            ).toFixed(2)}
          </p> */}
        </div>
      );
    }

    return (
      <>
        {collettive}
        {lprivate}
        {fast}
        {buoni}
        <Divider />
        {totale}
        {datiScuola.tipoBanca_2 > 0 && (
          <div style={{ marginTop: "42px" }}>
            <ScalapayFrase prezzo={(totPagare / 3).toFixed(2)} end={true} />
          </div>
        )}
        <Divider />
        {usaDatiCliente && idBuonoInUso === 0 && (
          <Segment textAlign="right" basic>
            <Input
              label={t("Codice_Buono")}
              action={{
                icon: "search",
                onClick: async () => {
                  await handleCodiceBuono();
                },
              }}
              value={codiceBuonoInUso}
              onChange={(ev, data) => {
                setCodiceBuonoInUso(data.value);
              }}
            ></Input>
          </Segment>
        )}
        {idBuonoInUso !== 0 && (
          <>
            <Segment textAlign="right" basic>
              <Input
                label={t("Codice_Buono")}
                action={{
                  icon: "remove",
                  onClick: () => {
                    setCodiceBuonoInUso("");
                    setIdBuonoInUso(0);
                    setImportoBuono(0);
                  },
                }}
                value={codiceBuonoInUso}
                disabled
              ></Input>
            </Segment>

            <label style={{ textAlign: "right" }}>Importo Buono</label>

            <p style={{ textAlign: "right" }} className="prezzo_parziale">
              {datiScuola.valuta}
              {" -"}
              {importoBuono.toFixed(2)}
            </p>
            <label style={{ textAlign: "right" }}>Totale da pagare</label>

            <p style={{ textAlign: "right" }} className="prezzo_totale">
              {datiScuola.valuta}{" "}
              {(importoTotaleTransazione - importoBuono).toFixed(2)}
            </p>
          </>
        )}
      </>
    );
  }
  if (isFastbookingCheck || isLoading) {
    return <Loader active size="huge"></Loader>;
  } else {
    return (
      <Container>
        <div className="head-offset"></div>
        <TestataPagina
          titolo={t("checkout")}
          sottoTitolo={t("inserisci_dati_pagamento")}
          icon="credit card"
        ></TestataPagina>
        {/* {appData.agenzia?.tipo === "agente" && (
          <Segment style={{ display: "flex", justifyContent: "center" }}>
            <ButtonGroup>
              <Button
                primary={!usaDatiCliente}
                onClick={() => {
                  setUsaDatiCliente(false);
                }}
              >
                Usa dati {appData.agenzia?.nomeAgenzia}
              </Button>
              <Button
                primary={usaDatiCliente}
                onClick={() => {
                  setUsaDatiCliente(true);
                }}
              >
                Usa dati Cliente finale
              </Button>
            </ButtonGroup>
          </Segment>
        )} */}
        <Segment
          className="padding_5_lr contenitore-grande"
          style={{ marginBottom: "30px" }}
        >
          <Grid stackable>
            <Grid.Column
              width="10"
              style={{
                paddingLeft: windowSize.width > 600 ? "14px" : "0px",
                paddingRight: windowSize.width > 600 ? "14px" : "0px",
              }}
            >
              {!usaDatiCliente && (
                <>
                  <Segment
                    inverted
                    textAlign="center"
                    className="skiwork-header"
                  >
                    {t("dati_cliente")}
                  </Segment>
                  <Segment basic textAlign="center">
                    <h3>ACQUISTO FATTO A NOME DALL'AGENTE / HOTEL</h3>
                  </Segment>
                </>
              )}
              {usaDatiCliente && (
                <>
                  <Segment
                    inverted
                    textAlign="center"
                    className="skiwork-header"
                  >
                    {t("dati_cliente")}
                  </Segment>
                  {/* <FormCliente
                    datiCliente={datiCliente}
                    datiScuola={datiScuola}
                    handleFieldChange={handleFieldChange}
                    listaNazioni={listaNazioni}
                    listaNazioniDial={listaNazioniDial}
                    listaLingue={listaLingue}
                    errors={errors}
                    isCheckout={true}
                    t={t}
                  /> */}
                  <FormClienteNew
                    datiCliente={datiCliente}
                    datiScuola={datiScuola}
                    handleFieldChange={handleFieldChange}
                    richiestaHotel={
                      datiScuola.richiestaHotel && datiScuola.hotels.length > 0
                    }
                    listaNazioni={listaNazioni}
                    listaNazioniDial={listaNazioniDial}
                    listaHotel={listaHotels}
                    listaProvince={listaProvince}
                    listaLingue={listaLingue}
                    errors={errors}
                    isCheckout={true}
                    t={t}
                    fatturazione={datiScuola.fatturazione}
                  />

                  <Segment
                    inverted
                    className="skiwork-header"
                    textAlign="center"
                  >
                    {t("note")}
                  </Segment>
                  <p style={{ textAlign: "center" }}>
                    <Form>
                      <TextArea
                        style={{
                          width: windowSize.width > 600 ? "400px" : "auto",
                        }}
                        placeholder={t("note")}
                        value={nota}
                        onChange={handleNotaChange}
                      ></TextArea>
                    </Form>
                    {errors.causale && (
                      <FormError errore="INSERIRE LA CAUSALE DEL PAGAMENTO" />
                    )}
                  </p>
                  <Segment
                    inverted
                    className="skiwork-header"
                    textAlign="center"
                  >
                    {t("condizioni_vendita_privacy")}
                  </Segment>
                  <Form>
                    <div
                      style={{ cursor: "pointer", marginBottom: "14px" }}
                      onClick={() => {
                        setShowCondizioni(true);
                      }}
                    >
                      <a href>
                        {t("condizioni_vendita")} <Icon name="external"></Icon>
                      </a>
                    </div>
                    <Form.Checkbox
                      //style={{ float: "left" }}
                      label={t("accetto_condizioni")}
                      checked={condizioniVendita}
                      onChange={handleCondizioniVenditaChange}
                      error={
                        errors.condizioniVendita
                          ? {
                              content: t("necessario_accettare_vendita"),
                              pointing: "left",
                            }
                          : null
                      }
                    ></Form.Checkbox>
                    <Divider />
                    <div
                      style={{ cursor: "pointer", marginBottom: "14px" }}
                      onClick={() => {
                        setShowPrivacy(true);
                      }}
                    >
                      <a href>
                        {t("informativa")} <Icon name="external"></Icon>
                      </a>
                    </div>
                    <Form.Checkbox
                      label={t("acconsento_privacy")}
                      checked={privacy}
                      name="privacy"
                      onChange={handlePrivacyChange}
                      error={
                        errors.privacy
                          ? {
                              content: t("necessario_accettare_privacy"),
                              pointing: "left",
                            }
                          : null
                      }
                    ></Form.Checkbox>
                    {datiScuola.richiediPrivacy2 && (
                      <>
                        <Form.Checkbox
                          label={datiScuola.titoloPrivacy2}
                          checked={privacy2}
                          name="privacy2"
                          onChange={handlePrivacy2Change}
                        ></Form.Checkbox>
                        {(datiScuola.testoPrivacy2?.length ?? 0) > 0 && (
                          <p
                            style={{
                              marginLeft: "32px",
                              marginTop: "-14px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowPrivacy2(true);
                            }}
                          >
                            <a href>
                              Maggiori informazioni{" "}
                              <Icon name="external"></Icon>
                            </a>
                          </p>
                        )}
                      </>
                    )}
                    {datiScuola.richiediPrivacy3 && (
                      <>
                        <Form.Checkbox
                          label={datiScuola.titoloPrivacy3}
                          checked={privacy3}
                          name="privacy3"
                          onChange={handlePrivacy3Change}
                        ></Form.Checkbox>
                        {(datiScuola.testoPrivacy3?.length ?? 0) > 0 && (
                          <p
                            style={{
                              marginLeft: "32px",
                              marginTop: "-14px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowPrivacy3(true);
                            }}
                          >
                            <a href>
                              Maggiori informazioni{" "}
                              <Icon name="external"></Icon>
                            </a>
                          </p>
                        )}
                      </>
                    )}
                  </Form>
                  <Divider />
                </>
              )}
            </Grid.Column>
            <Grid.Column
              width="6"
              style={{
                paddingLeft: windowSize.width > 600 ? "14px" : "0px",
                paddingRight: windowSize.width > 600 ? "14px" : "0px",
              }}
            >
              <Segment inverted className="skiwork-header" textAlign="center">
                {t("riepilogo")}
              </Segment>
              <Segment basic>{renderTotale()}</Segment>
            </Grid.Column>
          </Grid>
          {appData.agenzia?.tipo !== "agente" && (
            <Segment basic textAlign="center">
              {datiScuola.tipoBanca_2 > 0 && (
                <>
                  <Segment
                    inverted
                    className="skiwork-header"
                    style={{ textAlign: "center" }}
                  >
                    PAGAMENTO
                  </Segment>
                  <div className="container-tipopagamento">
                    <Form
                      className="form-tipopagamento"
                      id="form-tipopagamento"
                    >
                      <Form.Field className="field-tipopagamento">
                        <Radio
                          label="Carta di credito"
                          value={0}
                          checked={tipoPagamento === 0}
                          onChange={handleTipoPagamentoChange}
                        />
                      </Form.Field>
                      {/* <div className="scalapay-container"> */}
                      <Form.Field className="field-tipopagamento scalapay">
                        <Radio
                          value={1}
                          checked={tipoPagamento === 1}
                          onChange={handleTipoPagamentoChange}
                        />
                        <Label
                          as="label"
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          Scalapay - 3 rate da{" "}
                          <span style={{ fontWeight: "900" }}>
                            € {(getTotalePagare() / 3).toFixed(2)}
                          </span>{" "}
                          senza interessi
                          <img
                            src={require(`../../assets/images/scalapay/badge.png`)}
                            alt="scalapay logo"
                            className="scalapay-logo-small"
                          />
                        </Label>
                      </Form.Field>
                      {/* </div> */}

                      <Button
                        size="huge"
                        primary
                        onClick={checkDati}
                        style={{ width: "100%" }}
                      >
                        {t("effettua_pagamento")}
                      </Button>
                    </Form>
                  </div>
                </>
              )}
              {datiScuola.tipoBanca_2 <= 0 && (
                <>
                  {!showAxepta && (
                    <p style={{ textAlign: "center" }}>
                      <Button size="huge" primary onClick={checkDati}>
                        {t("effettua_pagamento")}
                      </Button>
                    </p>
                  )}
                  {showAxepta && (
                    <Segment textAlign="center" basic>
                      <div
                        id="my-axepta-sdk-pg"
                        style={{ margin: "0 auto" }}
                      ></div>
                    </Segment>
                  )}
                </>
              )}
            </Segment>
          )}
          {appData.agenzia?.tipo === "agente" && (
            <Segment basic textAlign="center">
              <Divider />
              <Button size="huge" primary onClick={checkDati}>
                ACQUISTA
              </Button>
            </Segment>
          )}
        </Segment>
        <TestoPrivacy
          isOpen={showPrivacy}
          onClose={() => {
            setShowPrivacy(false);
          }}
          t={t}
        />
        <TestoPrivacy
          isOpen={showPrivacy2}
          onClose={() => {
            setShowPrivacy2(false);
          }}
          t={t}
          numRichiesta={2}
        />
        <TestoPrivacy
          isOpen={showPrivacy3}
          onClose={() => {
            setShowPrivacy3(false);
          }}
          t={t}
          numRichiesta={3}
        />
        <TestoVendita
          isOpen={showCondizioni}
          onClose={() => {
            setShowCondizioni(false);
          }}
          t={t}
        />
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    cartData: state.cartReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  cartEmpty: cartActions.cartEmpty,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutMain);
