import * as types from "./actionTypes";

//ACTION CREATORS
export function setSlug(slug) {
  //debugger;
  return {
    type: types.SET_SLUG,
    slug,
  };
}

export function setLang(lang) {
  //debugger;
  return {
    type: types.SET_LANG,
    lang,
  };
}

export function setAgenzia(agenzia) {
  return {
    type: types.SET_AGENZIA,
    agenzia,
  };
}

export function setCorsoDettagli(dettagli) {
  return {
    type: types.BOOK_SET_CORSO_DETTAGLI,
    dettagli,
  };
}

export function setPrefsCorso(prefs) {
  return {
    type: types.BOOK_SET_PREFS_CORSO,
    prefs,
  };
}

export function setPrefsPrivate(prefs) {
  return {
    type: types.BOOK_SET_PREFS_PRIVATE,
    prefs,
  };
}

export function setTrackId(trackId) {
  return {
    type: types.APP_SET_TRACKID,
    trackId,
  };
}

// export function addServizioAllievo(numAllievo, servizio, prezzo) {
//   return {
//     type: types.BOOK_ADD_SERVIZIO_ALLIEVO,
//     numAllievo,
//     servizio,
//     prezzo,
//   };
// }

// export function resetStep2() {
//   return {
//     type: types.BOOK_RESET_STEP2,
//   };
// }

export function bookReset() {
  return {
    type: types.BOOK_RESET,
  };
}

export function clearAppData() {
  return {
    type: types.APP_CLEAR,
  };
}
